import React, { useEffect, useRef, createRef, useState } from "react";
import classnames from "classnames";
import moment from 'moment';
import _ from 'lodash';
import ReactDatetime from "react-datetime";
import { Input } from "reactstrap";
import PubSub from "pubsub-js";
import { useAuth0 } from "../../../react-auth0-spa";
import { Line } from 'react-chartjs-2';
import { hasPremiumSubscription } from "../../../utils/stripe";
import UpgradePrompt from "components/Stripe/UpgradePrompt.jsx";

const Analytics = (props) => {
  const { user, api } = useAuth0();
  const [ analyticsData, setAnalyticsData ] = useState({});
  const [ pageViewsData, setPageViewsData ] = useState({});
  const [ showDateFilterMenu, setShowDateFilterMenu ] = useState(false);
  const [ dateFilter, setDateFilter ] = useState('Today');

  const dateRanges = {
    'Today': {
      start: moment().subtract(1, 'day').toISOString(),
      end: moment().toISOString(),
      unit: 'hour'
    },
    'Last Week': {
      start: moment().subtract(1, 'week').toISOString(),
      end: moment().toISOString(),
      unit: 'day'
    },
    'Last Month': {
      start: moment().subtract(1, 'month').toISOString(),
      end: moment().toISOString(),
      unit: 'day'
    },
    'Last Year': {
      start: moment().subtract(1, 'year').toISOString(),
      end: moment().toISOString(),
      unit: 'month'
    },
    'All Time': {
      start: '2020-01-01',
      end: moment().toISOString(),
      unit: 'month'
    }
  };

  useEffect(() => {
    if (!hasPremiumSubscription(props.user.stripeCustomer)) {
      PubSub.publish('modal', (
        <UpgradePrompt />
      ));
    } else {
      getAnalytics();
    }
  }, [ dateFilter ]);

  const getAnalytics = async () => {

    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      let analytics = await api.getAnalytics(props.page.id, dateRanges[dateFilter].start, dateRanges[dateFilter].end, dateFilter);
      setAnalyticsData(analytics);


      let data = {};
      data.labels = analytics.pageViews.map(v => v.key);
      data.datasets = [{
        label: 'Page Views',
        data: analytics.pageViews.map(v => v.value),
        fill: false,
        backgroundColor: '#5850ec',
        borderColor: 'rgba(88, 80, 236, 0.4)',
        borderWidth: 4
      }];
      setPageViewsData(data);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const options = () => {
    return {
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            unit: dateRanges[dateFilter].unit
          }
        }],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => {
            let label = tooltipItem[0].label;

            if (label) {
              switch(dateRanges[dateFilter].unit) {
                case 'hour':
                  label = moment(label).format('h:mm a');
                  break;
                case 'day':
                  label = moment(label).format('ddd, MMM Do');
                  break;
                case 'month':
                  label = moment(label).format('MMM YYYY');
                  break;
                default:
                  break;
              }
            }

            return label;
          }
        }
      }
    }
  };

  const calcDiff = (old, current) => {
    let percentage = 0;

    if (old > 0) {
      percentage = (((current - old) / old) * 100).toFixed(1);
  
      if (!percentage || isNaN(parseFloat(percentage))) {
        percentage = 0;
      }
    }

    return {
      percentage: Math.abs(percentage),
      direction: percentage >= 0 ? 'up' : 'down'
    };
  }

  const pageViews = () => {
    let pageViews;
    if (analyticsData.eventTypeCount) {
      pageViews = analyticsData.eventTypeCount.find(t => t.key === 'pageView');
    }

    return pageViews ? pageViews.value : 0;
  }

  const prevPageViews = () => {
    let prevPageViews;
    if (analyticsData.prevEventTypeCount) {
      prevPageViews = analyticsData.prevEventTypeCount.find(t => t.key === 'pageView');
    }

    return prevPageViews ? prevPageViews.value : 0;
  }

  const clickRate = () => {
    let clickRate;
    if (analyticsData.eventTypeCount) {
      clickRate = analyticsData.eventTypeCount.find(t => t.key === 'click');
      let clicks = clickRate ? clickRate.value : 0;
      clickRate = clicks / pageViews();
    }

    return clickRate ? Math.round(clickRate * 100) : 0;
  }

  const prevClickRate = () => {
    let prevClickRate;
    if (analyticsData.prevEventTypeCount) {
      prevClickRate = analyticsData.prevEventTypeCount.find(t => t.key === 'click');
      let clicks = prevClickRate ? prevClickRate.value : 0;
      prevClickRate = clicks / pageViews();
    }

    return prevClickRate ? Math.round(prevClickRate * 100) : 0;
  }

  const sessionLength = () => {
    return analyticsData.sessionLength ? (analyticsData.sessionLength).toFixed(1) : 0;
  }

  const prevSessionLength = () => {
    return analyticsData.prevSessionLength ? (analyticsData.prevSessionLength).toFixed(1) : 0;
  }

  const referrerTable = () => {
    if (analyticsData.referrerCount) {
      let referrerCount = analyticsData.referrerCount.sort((a, b) => {
        return b.value - a.value;
      });

      return referrerCount.map((r, i) => {
        return (
          <tr className="bg-white" key={`referrer-${i}`}>
            <td className="max-w-0 px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-900">
              <div className="flex">
                <a href="#" className="group inline-flex space-x-2 truncate text-sm leading-5">
                  <p className="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition ease-in-out duration-150">
                    {r.key}
                  </p>
                </a>
              </div>
            </td>
            <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
              {r.value}
            </td>
          </tr>
        );
      });
    } else {
      return (<></>);
    }
  }

  const referrerTableSmall = () => {
    if (analyticsData.referrerCount) {
      let referrerCount = analyticsData.referrerCount.sort((a, b) => {
        return b.value - a.value;
      });

      return referrerCount.map((r, i) => {
        return (
          <li key={`referrer-${i}`}>
            <a href="#" className="block px-4 py-4 bg-white hover:bg-cool-gray-50">
              <div className="flex items-center space-x-4">
                <div className="flex-1 flex space-x-2 truncate">
                  <div className="text-cool-gray-500 text-sm truncate">
                    <p className="truncate">{r.key}</p>
                    <p><span className="text-cool-gray-900 font-medium">{r.value}</span></p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        );
      });
    } else {
      return (<></>);
    }
  }

  const linksTable = () => {
    if (analyticsData.linkClickCount && props.links) {
      let linkClickCount = analyticsData.linkClickCount.sort((a, b) => {
        return b.value - a.value;
      });

      return linkClickCount.map((r, i) => {
        return (
          <tr className="bg-white" key={`link-${i}`}>
            <td className="max-w-0 px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-900">
              <div className="flex">
                <a href="#" className="group inline-flex space-x-2 truncate text-sm leading-5">
                  <p className="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition ease-in-out duration-150">
                    {props.links.find(l => l.id == r.key) ? props.links.find(l => l.id == r.key).name : '-'}
                  </p>
                </a>
              </div>
            </td>
            <td className="px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
              {props.links.find(l => l.id == r.key) ? props.links.find(l => l.id == r.key).url : '-'}
            </td>
            <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
              {r.value}
            </td>
          </tr>
        );
      });
    } else {
      return (<></>);
    }
  }

  const linksTableSmall = () => {
    if (analyticsData.linkClickCount && props.links) {
      let linkClickCount = analyticsData.linkClickCount.sort((a, b) => {
        return b.value - a.value;
      });

      return linkClickCount.map((r, i) => {
        return (
          <li key={`link-${i}`}>
            <a href="#" className="block px-4 py-4 bg-white hover:bg-cool-gray-50">
              <div className="flex items-center space-x-4">
                <div className="flex-1 flex space-x-2 truncate">
                  <div className="text-cool-gray-500 text-sm truncate">
                    <p className="truncate">{props.links.find(l => l.id == r.key) ? props.links.find(l => l.id == r.key).name : '-'}</p>
                    <p className="truncate">{props.links.find(l => l.id == r.key) ? props.links.find(l => l.id == r.key).url : '-'}</p>
                    <p><span className="text-cool-gray-900 font-medium">{r.value}</span></p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        );
      });
    } else {
      return (<></>);
    }
  }

  const dateFilterChanged = (val) => {
    setDateFilter(val);
    setShowDateFilterMenu(false);
  }

  return (
    <>
      <div class="flex items-center mb-6">
        <h3 className="flex-1 text-lg leading-6 font-medium text-gray-900">
          {dateFilter}
        </h3>

        <div className="relative">
          <span className="rounded-md shadow-sm">
            <button id="sort-menu" type="button" onClick={e => setShowDateFilterMenu(!showDateFilterMenu)} className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150" aria-haspopup="true" aria-expanded="false">
              {dateFilter}
              {/* <!-- Heroicon name: chevron-down --> */}
              <svg className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </span>
          {showDateFilterMenu && (
            <div className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg">
              <div className="rounded-md bg-white shadow-xs">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="sort-menu">
                  <a href="#" onClick={e => dateFilterChanged('Today')} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Today</a>
                  <a href="#" onClick={e => dateFilterChanged('Last Week')} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Last Week</a>
                  <a href="#" onClick={e => dateFilterChanged('Last Month')} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Last Month</a>
                  <a href="#" onClick={e => dateFilterChanged('Last Year')} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Last Year</a>
                  <a href="#" onClick={e => dateFilterChanged('All Time')} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">All Time</a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
        <div>
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-base leading-6 font-normal text-gray-900">
                Total Views
              </dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  {pageViews()}
                  <span className="ml-2 text-sm leading-5 font-medium text-gray-500">
                    from {prevPageViews()}
                  </span>
                </div>
                {calcDiff(prevPageViews(), pageViews()).direction === 'up' ? (
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                    <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">
                      Increased by
                    </span>
                    {calcDiff(prevPageViews(), pageViews()).percentage}%
                  </div>
                ) : (
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                    <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">
                      Decreased by
                    </span>
                    {calcDiff(prevPageViews(), pageViews()).percentage}%
                  </div>
                )}
              </dd>
            </dl>
          </div>
        </div>
        <div className="border-t border-gray-200 md:border-0 md:border-l">
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-base leading-6 font-normal text-gray-900">
                Click Rate
              </dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  {clickRate()}%
                  <span className="ml-2 text-sm leading-5 font-medium text-gray-500">
                    from {prevClickRate()}%
                  </span>
                </div>
                {calcDiff(prevClickRate(), clickRate()).direction === 'up' ? (
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                    <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">
                      Increased by
                    </span>
                    {calcDiff(prevClickRate(), clickRate()).percentage}%
                  </div>
                ) : (
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                    <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">
                      Decreased by
                    </span>
                    {calcDiff(prevClickRate(), clickRate()).percentage}%
                  </div>
                )}
              </dd>
            </dl>
          </div>
        </div>
        <div className="border-t border-gray-200 md:border-0 md:border-l">
          <div className="px-4 py-5 sm:p-6">
            <dl>
              <dt className="text-base leading-6 font-normal text-gray-900">
                Avg. Session Length
              </dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  {sessionLength()}s
                  <span className="ml-2 text-sm leading-5 font-medium text-gray-500">
                    from {prevSessionLength()}s
                  </span>
                </div>
                {calcDiff(prevSessionLength(), sessionLength()).direction === 'up' ? (
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                    <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">
                      Increased by
                    </span>
                    {calcDiff(prevSessionLength(), sessionLength()).percentage}%
                  </div>
                ) : (
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                    <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">
                      Decreased by
                    </span>
                    {calcDiff(prevSessionLength(), sessionLength()).percentage}%
                  </div>
                )}
              </dd>
            </dl>
          </div>
        </div>
      </div>



      <h3 className="text-lg leading-6 font-medium text-gray-900 mt-8 mb-6">
        Page Views
      </h3>

      <div className="rounded-lg bg-white overflow-hidden shadow p-4 pt-5">
        <Line data={pageViewsData} options={options()} />
      </div>
        




      <div className="mt-5 grid grid-cols-1 md:grid-cols-2">

        <div className="">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mt-8 mb-6">
            Top Links
          </h3>

          {/* <!-- Activity list (smallest breakopoint only) --> */}
          <div className="shadow sm:hidden sm:rounded-lg">
            <ul className="mt-2 divide-y divide-cool-gray-200 overflow-hidden shadow sm:hidden">
              {linksTableSmall()}
            </ul>
          </div>

          {/* <!-- Activity table (small breakopoint and up) --> */}
          <div className="hidden sm:block">
            <div className="mx-auto">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-cool-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                          Link Name
                        </th>
                        <th className="px-6 py-3 bg-cool-gray-50 text-center text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                          URL
                        </th>
                        <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                          Clicks
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-cool-gray-200">
                      {linksTable()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="ml-0 mt-8 md:mt-0 md:ml-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mt-8 mb-6">
            Top Referrers
          </h3>

          {/* <!-- Activity list (smallest breakopoint only) --> */}
          <div className="shadow sm:hidden sm:rounded-lg">
            <ul className="mt-2 divide-y divide-cool-gray-200 overflow-hidden shadow sm:hidden">
              {referrerTableSmall()}
            </ul>
          </div>

          {/* <!-- Activity table (small breakopoint and up) --> */}
          <div className="hidden sm:block">
            <div className="mx-auto">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-cool-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                          Referrer
                        </th>
                        <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                          Views
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-cool-gray-200">
                      {referrerTable()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
}

export default Analytics;