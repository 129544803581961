import React, { useState } from "react";
import _ from 'lodash';
import PubSub from "pubsub-js";
import auth0 from "auth0-js";

const webAuth = new auth0.WebAuth({
  domain: 'johnbernazzani.us.auth0.com',
  clientID: 'ds8vq37zwGXZDEv4Ygd7uQJ2OA9pfQ1g',
  redirectUri: 'http://localhost:3000'
});

const Login = (props) => {
  const [ loginObject, setLoginObject ] = useState({
    username: '',
    password: ''
  });
  const [ emailError, setEmailError ] = useState(null);
  const [ passwordError, setPasswordError ] = useState(null);

  const login = async (e) => {
    e.preventDefault();
    setEmailError(null);
    setPasswordError(null);

    if (validateLoginObject()) {
      PubSub.publish('loading', true);
      let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);
  
      try {
        let login = await webAuth.login({
          realm: 'Username-Password-Authentication',
          username: loginObject.username,
          password: loginObject.password,
          responseType: 'code'
        }, (err, data) => {
          if (err) {
            setEmailError(err.description);
            setPasswordError(err.description);
          } else {

          }
        });
      } catch(err) {
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      } finally {
        PubSub.publish('loading', false);
        clearTimeout(timeout);
      }
    }
  }

  const validateLoginObject = () => {
    let username = loginObject.username.trim();
    if (!username || username === '') {
      setEmailError('Cannot be empty.');
      return false;
    }

    let password = loginObject.password.trim();
    if (!password || password === '') {
      setPasswordError('Cannot be empty.');
      return false;
    }

    return true;
  }

  const updating = (field, event) => {
    event.persist();
    let value = event.target.value;

    let updatedLoginObject = Object.assign({}, loginObject);
    _.set(updatedLoginObject, field, value);

    setLoginObject(updatedLoginObject);
  }

  const signup = (event) => {
    event.preventDefault();
    
  }

  const forgotPassword = (event) => {
    event.preventDefault();
    
  }

  return (
    <>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src="https://fuzz.link/logo-horizontal-on-white.png" alt="FuzzLink" />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
            Or
            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
              start your 14-day free trial
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                  Email address
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="email"
                    type="email"
                    required
                    className={`form-input appearance-none block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${emailError ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red' : ''}`}
                    ariaInvalid={emailError}
                    ariaDescribedBy="email-error"
                    onChange={e =>
                      updating("username", e)
                    }
                  />
                  {emailError && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </div>
                  )}
                </div>
                {emailError && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">{emailError}</p>
                )}
              </div>

              <div className="mt-6">
                <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                  Password
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="password"
                    type="password"
                    required
                    className={`form-input appearance-none block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${passwordError ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red' : ''}`}
                    ariaInvalid={passwordError}
                    ariaDescribedBy="password-error"
                    onChange={e =>
                      updating("password", e)
                    }
                  />
                  {passwordError && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </div>
                  )}
                </div>
                {passwordError && (
                  <p className="mt-2 text-sm text-red-600" id="password-error">{passwordError}</p>
                )}
              </div>

              <div className="mt-6 flex items-center justify-between">
                <div className="flex items-center">
                  <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                  <label htmlFor="remember_me" className="ml-2 block text-sm leading-5 text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-5">
                  <a onClick={e => forgotPassword(e)} href="#" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button onClick={e => login(e)} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                    Sign in
                  </button>
                </span>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm leading-5">
                  <span className="px-2 bg-white text-gray-500">
                    Don't have an account?
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button onClick={e => signup(e)} type="button" className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out" aria-label="Sign in with Twitter">
                    Sign up
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
