import React, { useEffect, useRef, createRef, useState } from "react";
import classnames from "classnames";
import moment from 'moment';
import _ from 'lodash';
import ReactDatetime from "react-datetime";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Input } from "reactstrap";
import { ChromePicker } from 'react-color';
import PubSub from "pubsub-js";
import { useAuth0 } from "../../../react-auth0-spa";
import { hasPremiumSubscription } from "../../../utils/stripe";
import UpgradePrompt from "components/Stripe/UpgradePrompt.jsx";
import Helper from "components/Helper.jsx";

const Links = (props) => {
  const { user, api } = useAuth0();
  const [ visibleColorPicker, setVisibleColorPicker ] = useState(null);
  const [ showSmartOrderHelper, setShowSmartOrderHelper ] = useState(false);

  const addLink = async () => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    let body = {
      url: '',
      name: '',
      isActive: false
    };

    if (!hasPremiumSubscription(user.stripeCustomer) && props.links.length > 2) {
      PubSub.publish('modal', (
        <UpgradePrompt />
      ));

      PubSub.publish('loading', false);
      clearTimeout(timeout);
      return false;
    }

    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      await api.createLink(props.page.id, body);
      PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'New link was created' });

      setTimeout(() => {
        props.scrollableView.current.scrollTop = props.scrollableView.current.scrollHeight;
      }, 500);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
      props.refreshPage();
    }
  }

  const deleteLink = async (linkId) => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    let body = { deletedAt: moment().format(), isActive: false, position: null };

    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      await api.updateLink(props.page.id, linkId, body);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
      props.refreshPage();
    }
  }

  const toggleSmartOrder = async () => {
    if (!hasPremiumSubscription(user.stripeCustomer)) {
      PubSub.publish('modal', (
        <UpgradePrompt />
      ));
    } else {
      PubSub.publish('loading', true);
      let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);
  
      let smartOrderEnabled = !props.page.autoPositionEnabled;
  
      let body = { autoPositionEnabled: smartOrderEnabled };
  
      try {
        // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
        await api.updatePage(props.page.id, body);
        PubSub.publish('alert', { type: 'success', title: 'Success', body: `Smart Order is ${smartOrderEnabled ? 'enabled' : 'disabled'}` });
      } catch(err) {
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      } finally {
        PubSub.publish('loading', false);
        clearTimeout(timeout);
        props.refreshPage();
      }
    }
  }

  const openSmartOrderHelper = async () => {
    setShowSmartOrderHelper(true);
    PubSub.publish('modal', (
      <Helper title="Smart Order" body="Enabling this feature allows us to automagically put your most popular links at the top of your page. Over time, we learn which of your links perform the best and place those at the top of your page." close={closeSmartOrderHelper} />
    ));
  }

  const closeSmartOrderHelper = async () => {
    setShowSmartOrderHelper(false);
    PubSub.publish('modal', null);
  }

  const updateLink = async (linkId, body) => {
    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      await api.updateLink(props.page.id, linkId, body);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      props.refreshPage();
    }
  }

  const linkUpdating = (link, field, event, color) => {
    let value = '';

    switch(field) {
      case 'isActive':
        event.persist();
        value = !link[field];
        updateLink(link.id, { [field]: value });
        break;
      case 'options.outline':
        event.persist();

        if (link.options && link.options.outline) {
          value = false;
        } else {
          value = true;
        }

        let keys = field.split('.');
        let firstKey = keys[0];
        let sub;

        if (keys.length > 1) {
          _.set(link, field, value);
          sub = link[firstKey];
        }

        updateLink(link.id, { [firstKey]: sub || value });
        break;
      case 'startDate':
        value = event;
        updateLink(link.id, { [field]: event.format() });
        break;
      case 'endDate':
        value = event;
        updateLink(link.id, { [field]: event.format() });
        break;
      case 'isTimed':
        if (!hasPremiumSubscription(user.stripeCustomer)) {
          PubSub.publish('modal', (
            <UpgradePrompt />
          ));
        } else {
          value = !link[field];
          updateLink(link.id, { [field]: value });
        }
        break;
      case 'options.backgroundColor':
        value = color.hex;
        break;
      default:
        event.persist();
        value = event.target.value;
        break;
    }

    let updatedLinks = Object.assign([], JSON.parse(JSON.stringify(props.links)));

    updatedLinks = updatedLinks.map(l => {
      if (l.id === link.id) {
        _.set(l, field, value);
      }
      return l;
    });

    props.setLinks(updatedLinks);
  }

  const linkUpdated = (link, field, event, val) => {
    if (event) {
      event.persist();
      let value = val || event.target.value;
      let keys = field.split('.');
      let firstKey = keys[0];
  
      if (keys.length > 1) {
        _.set(link, field, value);
        value = link[firstKey];
      }

      let prevLink = props.prevLinks.find(l => l.id == link.id);

      if (prevLink && value !== prevLink[firstKey]) {
        updateLink(link.id, { [firstKey]: value });
      }
    }
  }

  const reorderLinks = async (links) => {
    links = links.map(l => l.id);
    let body = { positions: links };

    try {
      await api.updatePage(props.page.id, body);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      props.refreshPage();
    }
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      props.links,
      result.source.index,
      result.destination.index
    );

    props.setLinks(items);

    reorderLinks(items);
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const outlineIconStyle = (link) => {
    let defaultStyle = {
      // backgroundColor: '#5e72e4',
      // borderColor: '#5e72e4',
      color: '#e2e2e2'
    };

    if (link.options) {
      if (link.options.outline) {
        return defaultStyle;
      } else {
        return {
          // backgroundColor: link.options.backgroundColor || '#5e72e4',
          // borderColor: link.options.backgroundColor || '#5e72e4',
          color: link.options.backgroundColor || '#e2e2e2'
        };
      }
    } else {
      return defaultStyle;
    }
  }

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Links</h3>
      <p className="mt-1 mb-4 text-sm leading-5 text-gray-600">
        Add new links to your page and customize their style. You can also drag and drop to reorder your links as well as schedule them to show at a certain time!
      </p>
      
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <button
            className="w-full items-center px-4 py-3 mb-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
            type="button"
            onClick={addLink}
          >
            <i className="fa fa-plus mr-2"></i>
            Add New Link
          </button>
        </div>

        <div className="relative">
          <button
            className={`w-full items-center px-4 py-3 mb-4 border border-transparent text-base leading-6 font-medium rounded-md ${props.page.autoPositionEnabled ? 'text-white bg-green-400 hover:bg-green-300 focus:border-green-400 focus:shadow-outline-green' : 'text-gray-400 bg-gray-200 hover:text-white hover:bg-green-200 focus:border-green-400 focus:shadow-outline-green'} focus:outline-none transition ease-in-out duration-150`}
            type="button"
            onClick={toggleSmartOrder}
          >
            <i className={`fa fa-${props.page.autoPositionEnabled ? 'rocket' : 'ban'} mr-2`}></i>
            Smart Order is {props.page.autoPositionEnabled ? 'Enabled' : 'Disabled'}
          </button>
          <button
            className={`bg-white hover:bg-gray-100 text-indigo-500 rounded-full h-8 w-8 flex items-center justify-center p-2 text-sm absolute top-0 right-0 -mt-2 -mr-2 shadow`}
            type="button"
            onClick={openSmartOrderHelper}
          >
            <i className="fa fa-question"></i>
          </button>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.links && props.links.length > 0 && props.links.map((link, index) => (
                <Draggable key={link.id} draggableId={link.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="mb-4"
                      {...provided.draggableProps}
                    >
                      <div
                        className={classnames({ 'card-link': true, 'with-handle': true, 'dragging': snapshot.isDragging, 'bg-white rounded-lg shadow': true })}
                      >
                        <div className="card-handle border-r border-gray-200" {...provided.dragHandleProps}>
                          <i className="fa fa-arrows-alt-v"></i>
                        </div>
                        <div className="p-3 pb-2 flex-1">
                          <div className="mt-0">
                            <input
                              className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                              placeholder="ex. My Instagram Page"
                              value={link.name}
                              onChange={e =>
                                linkUpdating(link, "name", e)
                              }
                              onBlur={e =>
                                linkUpdated(link, "name", e)
                              }
                            />
                          </div>
                          <div className="mt-2">
                            <input
                              className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                              placeholder="ex. https://instagram.com/user"
                              value={link.url}
                              onChange={e =>
                                linkUpdating(link, "url", e)
                              }
                              onBlur={e =>
                                linkUpdated(link, "url", e)
                              }
                            />
                          </div>
                          {link.isTimed && (
                            <div className="flex mt-2">
                              <div className="flex-1 sm:w-full md:w-1/2">
                                <ReactDatetime
                                  inputProps={{
                                    placeholder: "Link Start Date & Time",
                                    className: "form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  }}
                                  value={moment(link.startDate)}
                                  dateFormat={'MMM Do, YYYY'}
                                  onChange={e =>
                                    linkUpdating(link, "startDate", e)
                                  }
                                />
                              </div>
                              <div className="flex items-center justify-center hidden md:flex px-2" style={{ height: 'calc(2.25rem + 2px)' }}>
                                <i className="fa fa-arrow-right"></i>
                              </div>
                              <div className="flex-1 sm:w-full md:w-1/2">
                                <ReactDatetime
                                  inputProps={{
                                    placeholder: "Link End Date & Time",
                                    className: "form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  }}
                                  value={moment(link.endDate)}
                                  dateFormat={'MMM Do, YYYY'}
                                  onChange={e =>
                                    linkUpdating(link, "endDate", e)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="flex mt-2 relative">
                            <div className="link-button" onClick={e => deleteLink(link.id)}>
                              <i className="fa fa-trash-alt" style={{ color: 'red' }}></i>
                            </div>
                            <div className="link-button" onClick={e => linkUpdating(link, "isTimed", e)}>
                              <i className={`fa ${link.isTimed ? 'fa-calendar-check' : 'fa-calendar-times'}`} style={{ color: link.isTimed && link.options && link.options.backgroundColor ? link.options.backgroundColor : '#e2e2e2' }}></i>
                            </div>
                            <div className="link-button" onClick={e => setVisibleColorPicker(index)}>
                              <i className="fa fa-paint-brush" style={{ color: link.options && link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4' }}></i>
                            </div>

                            {visibleColorPicker === index ? (
                              <div className="popover-mobile sm:popover-desktop z-10">
                                <div
                                  style={cover}
                                  onClick={e => {
                                    linkUpdated(link, "options.backgroundColor", e, link.options && link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4');
                                    setVisibleColorPicker(null);
                                  }}
                                />
                                <ChromePicker
                                  color={link.options && link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4'}
                                  disableAlpha={true}
                                  onChange={(color, e) =>
                                    linkUpdating(link, "options.backgroundColor", e, color)
                                  }
                                />
                              </div>
                            ) : null}

                            <div className="link-button" onClick={e => linkUpdating(link, "options.outline", e)}>
                              {/* <span
                                className="outline-icon"
                                style={outlineIconStyle(link)}
                              ></span> */}
                              <i className="fa fa-adjust" style={outlineIconStyle(link)} />
                            </div>
                            <span className="flex mt-0 mr-0 mb-0 ml-auto text-sm items-center justify-center">
                              <span
                                onClick={e =>
                                  linkUpdating(link, "isActive", e)
                                }
                                role="checkbox"
                                tabIndex="0"
                                aria-checked="false"
                                className={`${link.isActive ? 'bg-green-400' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                              >
                                <span aria-hidden="true" className={`${link.isActive ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}></span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div onClick={addLink} className="col-span-1 flex flex-col justify-center items-center text-center text-gray-300 rounded-lg border-4 border-dashed border-gray-300 p-6 mb-4 cursor-pointer">
        <svg className="h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"/>
        </svg>
        <p className="text-xl font-semibold mt-2">Add New Link</p>
      </div>
    </>
  );
}

export default Links;