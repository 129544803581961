import React from "react";
import _ from 'lodash';
import PubSub from "pubsub-js";

import { useAuth0 } from "../../react-auth0-spa";
import history from "../../utils/history";

const UpgradePrompt = ({
  customerId,
  subscriptionId,
  currentProductSelected,
  newProductSelected,
  setSelectedProduct,
  setShowSubscriptionPage,
  invoicePreview,
  paymentMethod,
  refreshPage
}) => {
  const { user, api, stripeApi } = useAuth0();

  const viewPlans = async () => {
    history.push('/admin/account?tab=billing&pricing=true');
    PubSub.publish('modal', null);
  }

  return (
    <>    
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              Upgrade subscription
            </h3>
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500">
                Please upgrade your account to access premium features.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button onClick={e => viewPlans()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              View Plans
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
            <button onClick={e => PubSub.publish('modal', null)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </>
  );
}

export default UpgradePrompt;