import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import UpgradePrompt from "components/Stripe/UpgradePrompt.jsx";
import { useAuth0 } from "../../react-auth0-spa";
import history from "../../utils/history";
import { hasPremiumSubscription } from "../../utils/stripe";

const Home = (props) => {
  const { user, api } = useAuth0();
  const [ pages, setPages ] = useState([]);

  useEffect(() => {
    refreshPage();
  }, []);

  const refreshPage = async () => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      let pages = await api.getPages();
      pages = await Promise.all(pages.map(async (page) => {
        const photoUrl = `https://cdn.fuzz.link/${page.id}?${Date.now()}`;
        const photoExists = await fetch(photoUrl, {
          method: 'GET'
        });
        page.photoUrl = photoExists.status === 200 ? photoUrl : null;
        return page;
      }));
      setPages(pages);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const createNewPage = async () => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      if (!hasPremiumSubscription(user.stripeCustomer) && pages.length > 0) {
        PubSub.publish('modal', (
          <UpgradePrompt />
        ));
      } else {
        let newPage = await api.createPage();
        goToEditor(newPage.id, 'settings');
      }
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  // const togglePageActive = async (e, page) => {
  //   PubSub.publish('loading', true);
  //   let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

  //   let body = { isActive: !page.isActive };

  //   try {
  //     // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
  //     const data = await api.updatePage(page.id, body);
  //     //setPages(data.results);
  //   } catch(err) {
  //     PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
  //   } finally {
  //     PubSub.publish('loading', false);
  //     clearTimeout(timeout);
  //   }
  // }

  const goToEditor = (pageId, tab) => {
    history.push(`/admin/editor?pageId=${pageId}&tab=${tab}`);
  }

  // const goToAnalytics = (pageId) => {
  //   history.push(`/admin/dashboard?pageId=${pageId}`);
  // }

  return (
    <>
      <div className="md:flex md:items-center md:justify-between bg-white py-4 px-6 border-b border-gray-200">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate">
            My Pages
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <span className="ml-3 shadow-sm rounded-md">
            <button onClick={e => createNewPage()} type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-600 active:bg-indigo-600 transition duration-150 ease-in-out">
              Create New Page
              <i className="ml-2 fa fa-plus" />
            </button>
          </span>
        </div>
      </div>

      <div className="p-6 overflow-y-auto overflow-x-hidden">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {pages && pages.length > 0 && pages.map(page => {
            return (
              <li key={`page-${page.id}`} className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
                <div className="flex-1 flex flex-col p-8">
                  <div className="relative w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full object-cover overflow-hidden">
                    <svg className="h-full w-full text-gray-300 bg-gray-100" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                    {page.photoUrl && (
                      <img
                        alt=""
                        className="w-full h-full object-cover absolute top-0 left-0"
                        src={page.photoUrl}
                      />
                    )}
                  </div>
                  <h3 className="mt-6 text-gray-900 text-sm leading-5 font-medium">@{page.url || 'mypage'}</h3>
                  <dl className="mt-1 flex-grow flex flex-col justify-between">
                    <dt className="sr-only">Page Name</dt>
                    <dd className="text-gray-500 text-sm leading-5">{page.name || 'My Page Name'}</dd>
                    <dt className="sr-only">Number of Links</dt>
                    <dd className="mt-3">
                    <span onClick={e => goToEditor(page.id, 'links')} className="px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full cursor-pointer">{page.linkCount} link{page.linkCount != 1 ? 's' : ''}</span>
                    </dd>
                  </dl>
                </div>
                <div className="border-t border-gray-200">
                  <div className="-mt-px flex">
                    <div className="w-0 flex-1 flex border-r border-gray-200">
                      <a onClick={e => goToEditor(page.id, 'settings')} href="#" className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                        <i className="fa fa-sliders-h" />
                        <span className="ml-2">Editor</span>
                      </a>
                    </div>
                    <div onClick={e => goToEditor(page.id, 'analytics')} className="-ml-px w-0 flex-1 flex">
                      <a href="#" className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                        <i className="fa fa-chart-bar" />
                        <span className="ml-2">Analytics</span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
          <li onClick={e => createNewPage()} className="col-span-1 flex flex-col justify-center items-center text-center text-gray-300 rounded-lg border-4 border-dashed border-gray-300 p-8 cursor-pointer">
            <svg className="h-20 w-20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"/>
            </svg>
            <p className="text-xl font-semibold mt-4">Create New Page</p>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Home;
