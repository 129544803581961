import React, { useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./components/Auth0/PrivateRoute";
import Loading from "./components/Utility/Loading.jsx";
import Toast from "./components/Utility/Toast.jsx";
import Modal from "./components/Utility/Modal.jsx";

import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
import PubSub from "pubsub-js";

// router layouts
import AdminLayout from "./layouts/Admin.jsx";
// import AuthLayout from "./layouts/Auth.jsx";
// import ConsumerLayout from "./layouts/Consumer.jsx";

// pages
import Index from "./views/Index.jsx";
import Login from "./views/pages/Login.jsx";
import Page from "./views/pages/Page.jsx";

const App = () => {
  const { auth0Loading, user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ alert, setAlert ] = useState(null);
  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ modal, setModal ] = useState(null);

  let urlParams = new URLSearchParams(window.location.search);
  let planParam = urlParams.get('plan');

  const alertSubscriber = (msg, data) => {
    setAlert(data);
    setAlertVisible(true);

    setTimeout(() => {
      setAlertVisible(false);
    }, 3000);
  };
  const alertToken = PubSub.subscribe('alert', alertSubscriber);

  const loadingSubscriber = (msg, data) => {
    setIsLoading(data);
  };
  const loadingToken = PubSub.subscribe('loading', loadingSubscriber);

  const modalSubscriber = (msg, data) => {
    setModal(data);
  };
  const modalToken = PubSub.subscribe('modal', modalSubscriber);

  if (auth0Loading) {
    return <Loading />;
  }

  if (window.innerWidth < 1200) {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('g-sidenav-show');
    body.classList.remove('g-sidenav-pinned');
    body.classList.add('g-sidenav-hidden');
  }

  /**
   * This condition forces a redirect if the invited url qith wuery strings exists with a valid application id
   * DEPENDACY: application break feature. This way an application can be retrieved easily 
   */
  // if (applicationId) {
  //   return history.push(`application/${applicationId}?disabled=${disabled}`)
  // }
  // if (isAuthenticated && applicationId) {
  //   history.push(`/application/${applicationId}`)
  //   // return history.push(`application/${applicationId}?disabled=${disabled}`)
  // } 
  // else if (!isAuthenticated && applicationId) {
  //   loginWithRedirect({
  //     redirect_uri: window.location.href
  //   });
  // }

  if (!isAuthenticated) {
    if (window.location.pathname === '/signup') {
      loginWithRedirect({ plan: planParam || 'basic' });
      return <Loading />;
    } else {
      loginWithRedirect();
      return <Loading />;
    }
  } else {
    return (
      <>
        <Router history={history}>
          <Switch>
            <Redirect from="/login" to="/admin/home" />
            <Redirect from="/signup" to="/admin/home" />
            <Route exact path="/:url" component={Page} />
            <PrivateRoute path="/admin" component={AdminLayout} />
            <Redirect from="*" to="/admin/home" />
          </Switch>
        </Router>
        {isLoading && (
          <Loading />
        )}
        {alert && (
          <Toast visible={alertVisible} alert={alert} setAlertVisible={setAlertVisible} />
        )}
        {modal && (
          <Modal modal={modal} />
        )}
      </>
    );
  }
};

export default App;
