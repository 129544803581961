/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Home from "views/pages/Home.jsx";
import Editor from "views/pages/editor/Editor.jsx";
import Account from "views/pages/account/Account.jsx";
import Contact from "views/pages/Contact.jsx";

const routes = [
  {
    path: "/home",
    name: "My Pages",
    icon: "fa fa-home text-info",
    component: Home,
    layout: "/admin"
  },
  {
    path: "/account",
    name: "Account",
    icon: "fa fa-user text-info",
    component: Account,
    layout: "/admin"
  },
  {
    path: "/editor",
    component: Editor,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/contact",
    name: "Contact Us",
    icon: "fa fa-email text-info",
    component: Contact,
    layout: "/admin"
  }
];

export default routes;
