import React, { useState, useEffect, useContext } from "react";
import PubSub from "pubsub-js";
import createAuth0Client from "@auth0/auth0-spa-js";
import { API } from "./apis/index";
import { StripeAPI } from "./apis/stripe";
// import history from "./utils/history";

const DEFAULT_REDIRECT_CALLBACK = (appState) => {
  window.history.replaceState({}, document.title, window.location.pathname);
}

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState();
  const [ user, setUser ] = useState();
  const [ auth0Client, setAuth0 ] = useState();
  const [ popupOpen, setPopupOpen ] = useState(false);
  const [ api, setApi ] = useState();
  const [ stripeApi, setStripeApi ] = useState();
  const [ auth0Loading, setAuth0Loading ] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        let user = await auth0FromHook.getUser();

        try {
          let api = new API(auth0FromHook, user);
          setApi(api);
          let stripeApi = new StripeAPI(auth0FromHook, user);
          setStripeApi(stripeApi);
          // get user role
          /**
           * Get User Roleole
           * USAGE: this is currently only fetch additional data for a given role
           * FUTURE: this switch can later become a conditional loop that handles multi-role data retrieval
           */
          //const role = await api.getRole(); //TTFB > from manual code instead of within class but < likely due to cache
          let appUser = await api.getUser();
          let stripeCustomer = await stripeApi.getCustomer(appUser.user_metadata.stripeCustomerId);
          user = Object.assign({}, user, { appUser, stripeCustomer });
          // END get user role
        } catch (error) {
          user = Object.assign({}, user, {"error": error});
        }
        setUser(user);
      }

      PubSub.publish('loading', false);
      setAuth0Loading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    PubSub.publish('loading', true);
    setAuth0Loading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    PubSub.publish('loading', false);
    setAuth0Loading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const refreshUser = async () => {
    let user = await auth0Client.getUser();

    try {
      let appUser = await api.getUser();
      let stripeCustomer = await stripeApi.getCustomer(appUser.user_metadata.stripeCustomerId);
      user = Object.assign({}, user, { appUser, stripeCustomer });
      // END get user role
    } catch (error) {
      user = Object.assign({}, user, {"error": error});
    }
    setUser(user);
  };
  
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        refreshUser,
        auth0Client,
        api,
        stripeApi,
        auth0Loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
