import React, { useState } from "react";
import _ from 'lodash';
import PubSub from "pubsub-js";
import { useAuth0 } from "../../../react-auth0-spa";
import UpgradeConfirm from "components/Stripe/UpgradeConfirm.jsx";

const Subscription = (props) => {
  const { stripeApi } = useAuth0();
  const [ invoicePreview, setInvoicePreview ] = useState(null);
  const [ selectedProduct, setSelectedProduct ] = useState(false);
  const [ pricingPage, setPricingPage ] = useState('yearly');

  const STRIPE_PRICE_ID_FREE = 'price_1HFTILBeHyFamaEwr6BpOKBI';
  const STRIPE_PRICE_ID_PRO_MONTH = 'price_1I6JP4BeHyFamaEwZLxSjBaC';
  const STRIPE_PRICE_ID_PRO_YEAR = 'price_1HFTIMBeHyFamaEwHxnwD4ys';

  const upgradeConfirmation = async (newProductSelected) => {
    if (newProductSelected !== props.accountInformation.priceId) {
      PubSub.publish('loading', true);
      let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

      try {
        let response = await stripeApi.getUpcomingInvoice(props.accountInformation.id, props.accountInformation.subscription.id, newProductSelected);
        setInvoicePreview(response);
        PubSub.publish('modal', (
          <UpgradeConfirm
            customerId={props.accountInformation.subscription.customer}
            subscriptionId={props.accountInformation.subscription.id}
            currentProductSelected={selectedProduct}
            newProductSelected={newProductSelected}
            setSelectedProduct={setSelectedProduct}
            setShowSubscriptionPage={props.setShowSubscriptionPage}
            invoicePreview={response}
            paymentMethod={props.accountInformation.paymentMethods[0]}
            refreshPage={props.refreshPage}
          />
        ));
      } catch(err) {
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      } finally {
        PubSub.publish('loading', false);
        clearTimeout(timeout);
      }
    }
  }

  return (
    <>
      <section id="pricing" className="h-full">
        <div className="bg-gray-50 h-full">
          <div className="bg-gradient-to-b from-white to-gray-50">
            <div className="max-w-screen-xl mx-auto p-6">

              <div className="flex items-center justify-start">
                <span className="inline-flex rounded-md shadow-sm">
                  <button onClick={e => props.setShowSubscriptionPage(false)} type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    <i className="fa fa-chevron-left mr-2" />
                    Back
                  </button>
                </span>
              </div>

              <div className="sm:flex sm:flex-col sm:align-center mt-8">
                <h1 className="text-5xl leading-none font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
                <p className="mt-5 text-xl leading-7 text-gray-500 sm:text-center">Start reaching your audience today with our free Basic plan. Upgrade at any time.</p>
                <div className="relative mt-6 bg-gray-100 rounded-lg p-0.5 flex self-center sm:mt-8">
                  <button type="button" className={`relative ${pricingPage === 'yearly' ? 'bg-white border-gray-200 shadow-sm' : 'border border-transparent'} rounded-md py-2 w-1/2 text-sm leading-5 font-medium text-gray-700 whitespace-no-wrap hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8`} onClick={e => setPricingPage('yearly')}>Yearly billing</button>
                  <button type="button" className={`relative ${pricingPage === 'monthly' ? 'bg-white border-gray-200 shadow-sm' : 'border border-transparent'} rounded-md py-2 w-1/2 text-sm leading-5 font-medium text-gray-700 whitespace-no-wrap hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8`} onClick={e => setPricingPage('monthly')}>Monthly billing</button>
                </div>
              </div>

              <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto grid-cols-1">
                <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                  <div className="p-6">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Basic</h2>
                    <p className="mt-4 text-sm leading-5 text-gray-500">Free for life</p>
                    <p className="mt-8">
                      <span className="text-4xl leading-10 font-extrabold text-gray-900">$0</span>
                      <span className="text-base leading-6 font-medium text-gray-500">/mo</span>
                    </p>
                    {pricingPage === 'yearly' && (
                      <p className="mt-4">
                        <span className="text-sm leading-6 font-medium text-gray-500">Never billed</span>
                      </p>
                    )}
                    {props.accountInformation.priceId === STRIPE_PRICE_ID_FREE && (
                      <button type="button" className="mt-8 w-full bg-white border border-gray-200 border-transparent rounded-md py-2 text-sm leading-5 font-semibold text-indigo-600 hover:text-gray-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-purple active:text-indigo-700 transition duration-150 ease-in-out">Current Plan</button>
                    )}
                    {props.accountInformation.priceId !== STRIPE_PRICE_ID_FREE && (
                      <button onClick={e => upgradeConfirmation(STRIPE_PRICE_ID_FREE)} type="button" className="mt-8 w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm leading-5 font-semibold text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">Downgrade</button>
                    )}
                  </div>
                  <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                    <ul className="mt-6 space-y-4">
                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">1 page with custom URL</span>
                      </li>

                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">Up to 3 links</span>
                      </li>

                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">Customizable page and link appearance</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                  <div className="p-6 relative">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Pro</h2>
                    <p className="mt-4 text-sm leading-5 text-gray-500">To better target your audience</p>
                    <span class="absolute top-0 right-0 mr-4 mt-4 inline-flex items-center px-3 py-1 rounded text-md font-bold bg-red-100 text-red-600">
                      50% off!
                    </span>
                    <p className="mt-8">
                      <span className="text-4xl leading-10 font-extrabold text-gray-900">{pricingPage === 'yearly' ? (<><span className="line-through text-gray-400">$5</span> $2.50</>) : (<><span className="line-through text-gray-400">$8</span> $4</>)}</span>
                      <span className="text-base leading-6 font-medium text-gray-500">/mo</span>
                    </p>
                    {pricingPage === 'yearly' && (
                      <p className="mt-4">
                        <span className="text-sm leading-6 font-medium text-gray-500">Billed at <span className="line-through">$60</span> $30 per year</span>
                      </p>
                    )}
                    {((pricingPage === 'monthly' && props.accountInformation.priceId === STRIPE_PRICE_ID_PRO_MONTH) || (pricingPage === 'yearly' && props.accountInformation.priceId === STRIPE_PRICE_ID_PRO_YEAR)) ? (
                      <button type="button" className="mt-8 w-full bg-white border border-gray-200 border-transparent rounded-md py-2 text-sm leading-5 font-semibold text-indigo-600 hover:text-gray-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-purple active:text-indigo-700 transition duration-150 ease-in-out">Current Plan</button>
                    ) : (
                    <button onClick={e => upgradeConfirmation(pricingPage === 'yearly' ? STRIPE_PRICE_ID_PRO_YEAR : STRIPE_PRICE_ID_PRO_MONTH)} type="button" className="mt-8 w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm leading-5 font-semibold text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">{(pricingPage === 'monthly' && props.accountInformation.priceId === STRIPE_PRICE_ID_PRO_YEAR) ? 'Switch to monthly' : (pricingPage === 'yearly' && props.accountInformation.priceId === STRIPE_PRICE_ID_PRO_MONTH) ? 'Switch to yearly' : 'Upgrade to Pro!'}</button>
                    )}
                  </div>
                  <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                    <ul className="mt-6 space-y-4">
                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">Unlimited number of pages</span>
                      </li>

                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">Unlimited number of links</span>
                      </li>

                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">Customizable page and link appearance</span>
                      </li>

                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">Analytics dashboard</span>
                      </li>

                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">Smart link ordering</span>
                      </li>

                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">Scheduled links</span>
                      </li>

                      <li className="flex space-x-3">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm leading-5 text-gray-500">QR code</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Subscription;