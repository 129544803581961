import React from "react";
import _ from 'lodash';
import PubSub from "pubsub-js";

import { useAuth0 } from "../../react-auth0-spa";
import history from "../../utils/history";

const ReactivateConfirm = ({
  callback
}) => {
  return (
    <>    
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-200">
            <svg className="h-6 w-6 text-indigo-600" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
              <path d="M3 21V17M3 17V5C3 3.89543 3.89543 3 5 3H11.5L12.5 4H21L18 10L21 16H12.5L11.5 15H5C3.89543 15 3 15.8954 3 17ZM12 3.5V9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              Reactivate account?
            </h3>
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500">
                You will regain access to your free FuzzLink account.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <span className="flex w-full rounded-md items-center justify-center">
            <button onClick={e => callback()} type="button" className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Confirm Reactivation
            </button>
          </span>
        </div>
      </div>
    </>
  );
}

export default ReactivateConfirm;