import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import UpgradePrompt from "components/Stripe/UpgradePrompt.jsx";
import { useAuth0 } from "../../react-auth0-spa";
import history from "../../utils/history";
import { hasPremiumSubscription } from "../../utils/stripe";

const Contact = (props) => {
  const openEmail = () => {
    window.open('mailto:info@fuzz.link', '_blank');
  }

  return (
    <>
      <div className="md:flex md:items-center md:justify-between bg-white py-4 px-6 border-b border-gray-200">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate">
            Contact Us
          </h2>
        </div>
      </div>

      <div className="flex-grow w-full h-full lg:flex m-0 p-0 overflow-y-auto overflow-x-hidden">
        <div className={`flex flex-col p-0 w-full overflow-y-auto overflow-x-hidden`}>
          <div className="overflow-y-auto overflow-x-hidden h-full">
            <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto bg-white shadow sm:rounded-md px-4 py-5 sm:p-6">
                <div>
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                        Contact us by email!
                      </h3>
                      <p className="mt-1 text-md leading-5 text-gray-500">
                        Please send all questions, comments, and concerns to <a onClick={openEmail} href="#" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">info@fuzz.link</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
