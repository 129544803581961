import React, { useRef, useState, useEffect } from "react";
import _ from 'lodash';
import ReactS3Uploader from 'react-s3-uploader';
import PubSub from "pubsub-js";
import { useAuth0 } from "../../../react-auth0-spa";
import Cropper from 'react-cropper';
import { ChromePicker } from 'react-color';
import 'cropperjs/dist/cropper.css';
import Resizer from 'react-image-file-resizer';
import QRCode from 'qrcode.react';
import { hasPremiumSubscription } from "../../../utils/stripe";
import UpgradePrompt from "components/Stripe/UpgradePrompt.jsx";

const QRCodePage = (props) => {
  const { api } = useAuth0();

  useEffect(() => {
    if (!hasPremiumSubscription(props.user.stripeCustomer)) {
      PubSub.publish('modal', (
        <UpgradePrompt />
      ));
    }
  }, []);

  const downloadQR = () => {
    if (hasPremiumSubscription(props.user.stripeCustomer)) {
      const canvas = document.getElementById('qrCodeLarge');
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'FuzzLink_QRCode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      PubSub.publish('modal', (
        <UpgradePrompt />
      ));
    }
  };

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">QR Code</h3>
      <p className="mt-1 mb-4 text-sm leading-5 text-gray-600">
        Display this QR code online or on any printed materials so people can access your FuzzLink via their smartphone camera
      </p>

      <div className="mt-5 md:mt-0">
        <form action="#" method="POST">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white sm:p-6 flex align-middle justify-center">

              <QRCode id="qrCode" value={`https://fuzz.link/${hasPremiumSubscription(props.user.stripeCustomer) ? props.page.url : 'fuzzlink'}`} size={250} includeMargin={true} />
              <QRCode id="qrCodeLarge" value={`https://fuzz.link/${hasPremiumSubscription(props.user.stripeCustomer) ? props.page.url : 'fuzzlink'}`} size={750} style={{ display: 'none' }} includeMargin={true} />

            </div>
          </div>
        </form>
      </div>

      <button
        className="w-full items-center px-4 py-3 mt-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
        type="button"
        onClick={downloadQR}
      >
        <i className="fa fa-download mr-2"></i>
        Download QR Code
      </button>

    </>
  );
}

export default QRCodePage;