import React, { useRef, useState } from "react";
import _ from 'lodash';
import ReactS3Uploader from 'react-s3-uploader';
import PubSub from "pubsub-js";
import { useAuth0 } from "../../../react-auth0-spa";
import Cropper from 'react-cropper';
import { ChromePicker } from 'react-color';
import 'cropperjs/dist/cropper.css';
import Resizer from 'react-image-file-resizer';

const Settings = (props) => {
  const { api } = useAuth0();
  let photoInput = null;
  let cropper = useRef();
  const [ isCropping, setIsCropping ] = useState(false);
  const [ base64, setBase64 ] = useState(null);
  const [ urlError, setUrlError ] = useState(null);
  const [ visibleColorPicker, setVisibleColorPicker ] = useState(null);

  const updatePage = async (pageId, body) => {
    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      await api.updatePage(pageId, body);
      setUrlError(null);
    } catch(err) {
      if (body.url) {
        setUrlError(err);
      }
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      props.refreshPage();
    }
  }

  const pageUpdating = (field, event, color) => {
    let value = '';

    switch(field) {
      case 'options.backgroundColor':
        value = color.hex;
        break;
      case 'isActive':
        event.persist();
        value = !props.page[field];
        updatePage(props.page.id, { [field]: value });
        break;
      case 'url':
        event.persist();
        value = event.target.value;
        checkUrlValidity(value);
        break;
      default:
        event.persist();
        value = event.target.value;
        break;
    }

    let page = Object.assign({}, props.page);
    _.set(page, field, value);

    props.setPage(page);
  }

  const pageUpdated = (field, event, val) => {
    event.persist();

    let value = val || event.target.value;
    let keys = field.split('.');
    let firstKey = keys[0];

    if (keys.length > 1) {
      let page = Object.assign({}, props.page);
      _.set(page, field, value);
      value = page[firstKey];
    }

    if (value !== props.savedPage[firstKey]) {
      if (firstKey === 'url') {
        if (checkUrlValidity(value)) {
          updatePage(props.page.id, { [firstKey]: value });
        }
      } else {
        updatePage(props.page.id, { [firstKey]: value });
      }
    }
  }

  const checkUrlValidity = (url) => {
    let isValid = /^[0-9a-zA-Z_.-]+$/.test(url);

    if (!isValid) {
      setUrlError('URL can only container letters, numbers, underscores, periods, and hyphens');
    }

    return isValid;
  }

  // const colorPickerClicked = () => {
  //   colorPickerRef.current.click();
  //   colorPickerRef.current.focus();
  // }

  const getSignedUrl = async (file, callback) => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      const data = await api.generatePutUrl(props.page.id, file);
      callback(data);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const onUploadProgress = (e) => {
    PubSub.publish('loading', true);
  }

  const onUploadError = (err) => {
    PubSub.publish('loading', false);
    PubSub.publish('alert', { type: 'danger', title: 'Error', body: 'Unable to upload photo' });

    let photoInputEl = document.getElementById('photoInput');
    photoInputEl.value = null;
  }

  const onUploadFinish = (e) => {
    PubSub.publish('loading', false);
    PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'Photo was uploaded' });
    props.refreshPage();

    let photoInputEl = document.getElementById('photoInput');
    photoInputEl.value = null;
  }

  const onPreprocess = (file, next) => {
    // if (file.size > 1000) {
    //   PubSub.publish('alert', { type: 'danger', title: 'Error', body: 'Image size is greater than 1 kb limit' });
    // } else {
      

    //   next(file);
    // }

    return Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      uri => {
        uri.lastModifiedDate = new Date();
        uri.name = 'test';
        next(uri);
      },
      'blob',
      200,
      200,
    );
  }

  // const crop = (e) => {
  //   //console.log(cropper.getCroppedCanvas().toDataURL());
  // }

  // const onCropperInit = (cropper) => {
  //   console.log('cropper init!', cropper);
  //   //cropper = cropper;

  //   var img = document.getElementById('profilePhoto');
  //   var canvas = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   var ctx = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0);
  //   var dataURL = canvas.toDataURL("image/png");
  //   console.log(dataURL);
  //   let replaced = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  //   console.log(replaced);
  //   setBase64(dataURL);
  //   //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

  //   cropper.replace(dataURL);
  // }

  const selectFile = (e) => {
    photoInput.click();
  }

  const removePhoto = async (e) => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      await api.deletePhoto(props.page.id);
      PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'Photo was removed' });
      props.refreshPage();
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Page Settings</h3>
      <p className="mt-1 mb-4 text-sm leading-5 text-gray-600">
        Customize your FuzzLink URL, page name, photo, and background color.
      </p>

      <div className="mt-5 md:mt-0">
        <form action="#" method="POST">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="mt-0">
                <label htmlFor="about" className="block text-sm leading-5 font-medium text-gray-700 mb-2">
                  Public
                </label>
                <span
                  onClick={e =>
                    pageUpdating("isActive", e)
                  }
                  role="checkbox"
                  tabIndex="0"
                  aria-checked="false"
                  className={`${props.page.isActive ? 'bg-green-400' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                >
                  <span aria-hidden="true" className={`${props.page.isActive ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}></span>
                </span>
                <p className="mt-2 text-sm text-gray-500">
                  Toggle whether the page is publicly viewable
                </p>
              </div>

              <div className="mt-6">
                <label htmlFor="company_website" className="block text-sm font-medium leading-5 text-gray-700">
                  Custom Page URL
                </label>
                <div className="mt-1 relative flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    fuzz.link/
                  </span>
                  <input
                    id="company_website"
                    className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="mypage"
                    value={props.page.url}
                    onChange={e =>
                      pageUpdating("url", e)
                    }
                    onBlur={e =>
                      pageUpdated("url", e)
                    }
                  />
                  {urlError && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </div>
                  )}
                </div>
                {urlError && (
                  <p className="mt-2 text-sm text-red-600" id="password-error">{urlError}</p>
                )}
              </div>

              <div className="mt-6">
                <label htmlFor="about" className="block text-sm leading-5 font-medium text-gray-700">
                  Page Description
                </label>
                <input
                  id="first_name"
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  placeholder="My Page Description"
                  value={props.page.name}
                  onChange={e =>
                    pageUpdating("name", e)
                  }
                  onBlur={e =>
                    pageUpdated("name", e)
                  }
                />
                <p className="mt-2 text-sm text-gray-500">
                  Brief name or description of your page. Emojis are welcome!
                </p>
              </div>

              {/* <div className="mt-6">
                <label htmlFor="about" className="block text-sm leading-5 font-medium text-gray-700">
                  About
                </label>
                <div className="rounded-md shadow-sm">
                  <textarea id="about" rows="3" className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="you@example.com"></textarea>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Brief description for your profile. URLs are hyperlinked.
                </p>
              </div> */}

              <div className="mt-6">
                <label className="block text-sm leading-5 font-medium text-gray-700">
                  Photo
                </label>
                <div className="mt-2 flex items-center">
                  <span className="relative inline-block h-12 w-12 rounded-full object-cover overflow-hidden bg-gray-100">
                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                   
                    {props.photoUrl && (
                      <img
                        id="profilePhoto"
                        alt=""
                        className="w-full h-full object-cover absolute top-0 left-0"
                        src={props.photoUrl}
                      />
                    )}

                    <ReactS3Uploader
                      id="photoInput"
                      getSignedUrl={getSignedUrl}
                      accept="image/*"
                      s3path="/"
                      preprocess={onPreprocess}
                      onProgress={onUploadProgress}
                      onError={onUploadError}
                      onFinish={onUploadFinish}
                      uploadRequestHeaders={{
                        'Cache-Control': 'max-age=0'
                      }}
                      contentDisposition="auto"
                      scrubFilename={(filename) => filename.replace(/\*/g, props.page.id)}
                      inputRef={ref => photoInput = ref}
                      autoUpload={true}
                      style={{ display: 'none' }}
                    />

                    {/* {isCropping && (
                      <Cropper
                        ref={cropper}
                        src={base64}
                        style={{height: 400, width: 400}}
                        // Cropper.js options
                        initialAspectRatio={1 / 1}
                        guides={true}
                        crop={crop}
                        onInitialized={onCropperInit}
                        dragMode="move"
                        viewMode={2}
                      />
                    )} */}

                  </span>
                  <span className="ml-5 rounded-md shadow-sm">
                    <button onClick={selectFile} type="button" className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                      Change
                    </button>
                  </span>
                  <span className="ml-3 rounded-md shadow-sm">
                    <button onClick={removePhoto} type="button" className="py-2 px-3 border border-red-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-red-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                      Remove
                    </button>
                  </span>
                </div>
              </div>

              {/* <div className="mt-6">
                <label className="block text-sm leading-5 font-medium text-gray-700">
                  Cover photo
                </label>
                <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="text-center">
                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                      <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p className="mt-1 text-sm text-gray-600">
                      <button className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out">
                        Upload a file&nbsp;
                      </button>
                      or drag and drop
                    </p>
                    <p className="mt-1 text-xs text-gray-500">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div> */}

              <div className="mt-6">
                <label htmlFor="about" className="block text-sm leading-5 font-medium text-gray-700 mb-2">
                  Background Color
                </label>
                <div onClick={() => { setVisibleColorPicker(true); }} className="mt-1 form-input block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 cursor-pointer">
                  <div className="w-full rounded-sm" style={{ height: 20, backgroundColor: props.page.options && props.page.options.backgroundColor ? props.page.options.backgroundColor : '#172b4d' }}></div>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Customize your page's background color
                </p>
              </div>

              {visibleColorPicker ? (
                <div className="popover-mobile sm:popover-desktop z-10">
                  <div
                    style={cover}
                    onClick={e => {
                      pageUpdated("options.backgroundColor", e, props.page.options && props.page.options.backgroundColor ? props.page.options.backgroundColor : '#172b4d');
                      setVisibleColorPicker(null);
                    }}
                  />
                  <ChromePicker
                    color={props.page.options && props.page.options.backgroundColor ? props.page.options.backgroundColor : '#172b4d'}
                    disableAlpha={true}
                    onChange={(color, e) =>
                      pageUpdating("options.backgroundColor", e, color)
                    }
                  />
                </div>
              ) : null}

            </div>
          </div>
        </form>
      </div>

    </>
  );
}


export default Settings;