import React from "react";
import _ from 'lodash';
import PubSub from "pubsub-js";
import { useAuth0 } from "../../react-auth0-spa";
import PaymentForm from "./PaymentForm.jsx";

const STRIPE_PRICE_ID_FREE = 'price_1HFTILBeHyFamaEwr6BpOKBI';
const STRIPE_PRICE_ID_PRO_MONTH = 'price_1I6JP4BeHyFamaEwZLxSjBaC';
const STRIPE_PRICE_ID_PRO_YEAR = 'price_1HFTIMBeHyFamaEwHxnwD4ys';

const UpgradeConfirm = ({
  customerId,
  subscriptionId,
  newProductSelected,
  setSelectedProduct,
  setShowSubscriptionPage,
  invoicePreview,
  paymentMethod,
  refreshPage
}) => {
  const { stripeApi, user } = useAuth0();

  const confirmPriceChange = async () => {
    if (paymentMethod && paymentMethod.id) {
      PubSub.publish('loading', true);
      let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);
  
      try {
        await stripeApi.updateSubscription(subscriptionId, { customerId, newPriceId: newProductSelected, paymentMethodId: paymentMethod.id });
        updateSubscriptionSuccessful();
      } catch(err) {
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      } finally {
        PubSub.publish('loading', false);
        clearTimeout(timeout);
      }
    } else {
      PubSub.publish('modal', (
        <PaymentForm
          customerId={customerId}
          setShowSubscriptionPage={setShowSubscriptionPage}
          callback={async (paymentMethodId) => {
            PubSub.publish('loading', true);
            let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);
        
            try {
              await stripeApi.updateSubscription(subscriptionId, { customerId, newPriceId: newProductSelected, paymentMethodId });
              updateSubscriptionSuccessful();
            } catch(err) {
              PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
            } finally {
              PubSub.publish('loading', false);
              clearTimeout(timeout);
            }
          }}
        />
      ));
    }
  }

  const updateSubscriptionSuccessful = () => {
    // google tag manager custom event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'subscriptionChange',
      'plan': newProductSelected,
      'price': newProductSelected === STRIPE_PRICE_ID_PRO_MONTH ? '8.00' : newProductSelected === STRIPE_PRICE_ID_PRO_YEAR ? '60.00' : '0.00',
      'email': user.email
    });

    setSelectedProduct(newProductSelected);
    PubSub.publish('modal', null);
    PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'Subscription updated' });
    setShowSubscriptionPage(false);
    refreshPage();
  }

  const getFormattedAmount = (amount) => {
    // Format price details and detect zero decimal currencies
    let amountToFormat = amount;
    let numberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    });
    let parts = numberFormat.formatToParts(amountToFormat);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
      if (part.type === 'decimal') {
        zeroDecimalCurrency = false;
      }
    }
    amountToFormat = zeroDecimalCurrency ? amount : amount / 100;
    let formattedAmount = numberFormat.format(amountToFormat);
  
    return formattedAmount;
  }
  
  const getDateStringFromUnixTimestamp = (date) => {
    let nextPaymentAttemptDate = new Date(date * 1000);
    let day = nextPaymentAttemptDate.getDate();
    let month = nextPaymentAttemptDate.getMonth() + 1;
    let year = nextPaymentAttemptDate.getFullYear();
  
    return month + '/' + day + '/' + year;
  }

  return (
    <>    
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              Change subscription
            </h3>
            <div className="mt-2">
              {invoicePreview && (
                <p className="text-sm leading-5 text-gray-500">
                  You will be charged {(invoicePreview && getFormattedAmount(invoicePreview.amount_due)) || ''}{' '}on{' '}
                  <span>
                    {(invoicePreview.next_payment_attempt && getDateStringFromUnixTimestamp(invoicePreview.next_payment_attempt)) || ''}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button onClick={e => confirmPriceChange()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Confirm
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
            <button onClick={e => PubSub.publish('modal', null)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </>
  );
}

export default UpgradeConfirm;