import config from "../config/index";

/**
 * IMPORTANT: this class is instantiated from within the auth0 provider function and becomes accessable after importing useAuth0 via: import { useAuth0 } from "../react-auth0-spa";
 * - It is not recommended to instantiated it manually outside of auth0 because it depends on auth0 specific perameters that allow the use of the access token for the logged in user.
 */
export class StripeAPI {
  accessToken = Promise
  user = {}
  auth0Client = {}
  test = "test"
  url = ""
  constructor (auth0Client, user) {
    this.auth0Client = auth0Client;
    this.user = user;
    this.accessToken = auth0Client.getTokenSilently();
  }

  handleResponse = async (response) => {
    const string = await response.text();
    let json = {};
    if (typeof string === 'string' && string.trim() !== '') {
      try {
        json = JSON.parse(string);
      } catch(err) {
        console.log('Invalid error received from server: ', err);
      }
    }

    if (response.status === 200) {
      // success
      return json;
    } else {
      // error
      throw this.errorHandler(json);
    }
  }

  errorHandler = (err) => {
    let message = 'There was a problem with your request.';

    if (typeof err === 'string') {
      message = err;
    } else if (err.errors && err.errors.message) {
      message = err.errors.message;
    } else if (typeof message !== 'string') {
      message = JSON.stringify(message);
    }

    return message;
  }

  getCustomer = async (customerId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/customer/${customerId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  updateCustomer = async (customerId, body) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/customer/${customerId}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return await this.handleResponse(response);
  }

  createCustomerPaymentMethod = async (customerId, paymentMethodId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/customer/${customerId}/payment-methods`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        paymentMethodId: paymentMethodId
      })
    });
    return await this.handleResponse(response);
  }

  getCustomerPaymentMethod = async (paymentMethodId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/payment-method/${paymentMethodId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  detachCustomerPaymentMethod = async (paymentMethodId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/payment-method/${paymentMethodId}/detach`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  getUpcomingInvoice = async (customerId, subscriptionId, newPriceId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/subscription/${subscriptionId}/upcoming-invoice`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        customerId: customerId,
        newPriceId: newPriceId
      })
    });
    return await this.handleResponse(response);
  }

  getCustomerPaymentMethods = async (customerId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/customer/${customerId}/payment-methods`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  updateSubscription = async (subscriptionId, body) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/subscription/${subscriptionId}/update`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return await this.handleResponse(response);
  }

  cancelSubscription = async (subscriptionId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/stripe/subscription/${subscriptionId}/cancel`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  reactivateSubscription = async (customerId, subscriptionId) => {
    const accessToken = await this.accessToken;
    let url = `${config.apiUrl}/stripe/subscription/${subscriptionId}/reactivate`;

    if (!subscriptionId) {
      url = `${config.apiUrl}/stripe/subscription`;
    }

    const response = await fetch(url, {
      method: subscriptionId ? 'PUT' : 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customerId })
    });
    return await this.handleResponse(response);
  }
}
