import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, withRouter } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

import Index from "../../views/Index.jsx";

const PrivateRoute = ({ component: Component, path, location, ...rest }) => {
  const { isAuthenticated, loginWithRedirect, getTokenSilently } = useAuth0();

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        // if (location.search !== "") {
        //   await loginWithRedirect({
        //     appState: { targetUrl: location.search }
        //   });
        // }

        // let url = window.location.origin;
        // await loginWithRedirect({
        //   redirect_uri: 'http://localhost:3000/portal/merchant/applications'
        // });

        // if (
        //   window.location.pathname === '/portal/merchant/application' &&
        //   window.location.search.includes("applicationId=")
        // ) {
        //   url = 'http://localhost:3000/portal/merchant/applications'
        // }



        // await loginWithRedirect({
        //   redirect_uri: window.location.href
        // });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, path, location, getTokenSilently]);

  const render = (props) => {
    return isAuthenticated === true ? <Component {...props} /> : <Index />;
  }

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default withRouter(PrivateRoute);
