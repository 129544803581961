import React, { useState } from "react";
import _ from 'lodash';
import PubSub from "pubsub-js";

import { useAuth0 } from "../../../react-auth0-spa";
import PaymentForm from "components/Stripe/PaymentForm.jsx";
import CancelConfirm from "components/Stripe/CancelConfirm.jsx";

const Billing = (props) => {
  const { user, api, stripeApi, logout } = useAuth0();
  const [ subscriptionCancelled, setSubscriptionCancelled ] = useState(false);
  const [ newProductSelected, setNewProductSelected ] = useState('');
  const [ selectedProduct, setSelectedProduct ] = useState(
    props.customer.priceId
  );

  // const cancelSubscription = async () => {
  //   PubSub.publish('loading', true);
  //   let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

  //   try {
  //     let customer = await stripeApi.cancelSubscription(props.customer.subscription.id);
  //     setSubscriptionCancelled(true);
  //   } catch(err) {
  //     PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
  //   } finally {
  //     PubSub.publish('loading', false);
  //     clearTimeout(timeout);
  //   }
  // }

  const getFormattedAmount = (amount) => {
    // Format price details and detect zero decimal currencies
    let amountToFormat = amount;
    let numberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    });
    let parts = numberFormat.formatToParts(amountToFormat);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
      if (part.type === 'decimal') {
        zeroDecimalCurrency = false;
      }
    }
    amountToFormat = zeroDecimalCurrency ? amount : amount / 100;
    let formattedAmount = numberFormat.format(amountToFormat);
  
    return formattedAmount;
  }
  
  const getDateStringFromUnixTimestamp = (date) => {
    let nextPaymentAttemptDate = new Date(date * 1000);
    let day = nextPaymentAttemptDate.getDate();
    let month = nextPaymentAttemptDate.getMonth() + 1;
    let year = nextPaymentAttemptDate.getFullYear();
  
    return month + '/' + day + '/' + year;
  }

  const addCard = () => {
    PubSub.publish('modal', (
      <PaymentForm
        customerId={props.customer.id}
        callback={() => {
          props.refreshPage();
        }}
      />
    ));
  }

  const deleteCard = async () => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      await stripeApi.detachCustomerPaymentMethod(props.customer.paymentMethod.id);
      PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'Deleted payment method' });
      props.refreshPage();
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const cancelSubscription = async () => {
    PubSub.publish('modal', (
      <CancelConfirm
        cancellationDate={(props.customer.upcomingInvoice.next_payment_attempt && getDateStringFromUnixTimestamp(props.customer.upcomingInvoice.next_payment_attempt)) || ''}
        callback={async () => {
          PubSub.publish('loading', true);
          let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

          try {
            await stripeApi.cancelSubscription(props.customer.subscription.id);
            PubSub.publish('modal', null);
            PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'Account was deactivated' });
          } catch(err) {
            PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
          } finally {
            PubSub.publish('loading', false);
            clearTimeout(timeout);
          }
        }}
      />
    ));
  }

  return (
    <>
      <div className="max-w-3xl mx-auto bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Subscription
          </h3>
          <div className="mt-5">
            <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 sm:mt-0">
                  {props.customer && props.customer.subscription && (
                    <>
                      <div className="text-sm leading-5 font-medium text-gray-900">
                        {props.customer.subscription.plan.amount === 500 ? 'Premium' : 'Basic'} Plan
                      </div>
                      <div className="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
                        <div>
                          {getFormattedAmount(props.customer.subscription.plan.amount_decimal)} / {props.customer.subscription.plan.interval}
                        </div>
                        <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                          &middot;
                        </span>
                        <div className="mt-1 sm:mt-0">
                          Billed {props.customer.subscription.plan.interval === 'month' ? 'monthly' : 'yearly'}
                        </div>
                      </div>
                    </>
                  )}
                  {props.customer.upcomingInvoice && (
                    <div className="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
                      <div>
                        Next payment - {(props.customer.upcomingInvoice && getFormattedAmount(props.customer.upcomingInvoice.amount_due)) || ''}{' '}on{' '}
                        <span>
                          {(props.customer.upcomingInvoice.next_payment_attempt && getDateStringFromUnixTimestamp(props.customer.upcomingInvoice.next_payment_attempt)) || ''}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                <span className="inline-flex rounded-md shadow-sm mr-2">
                  <button onClick={e => props.setShowSubscriptionPage(true)} type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    Change Plan
                  </button>
                </span>
                <span className="inline-flex rounded-md shadow-sm mt-2 md:mt-0">
                  <button onClick={e => cancelSubscription()} type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150">
                    Deactivate
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-3xl mx-auto bg-white shadow sm:rounded-lg mt-6">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Payment method
          </h3>
          <div className="mt-5">
            {props.customer.paymentMethod && props.customer.paymentMethod.card && (
              <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                <div className="sm:flex sm:items-start">
                  <i className="fa fa-credit-card"/>
                  <div className="mt-3 sm:mt-0 sm:ml-4">
                    <div className="text-sm leading-5 font-medium text-gray-900">
                      Ending with {props.customer.paymentMethod.card.last4}
                    </div>
                    <div className="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
                      <div>
                        Expires {props.customer.paymentMethod.card.exp_month}/{props.customer.paymentMethod.card.exp_year}
                      </div>
                      <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                        &middot;
                      </span>
                      <div className="mt-1 sm:mt-0">
                        Last updated on {props.customer.paymentMethod.created ? getDateStringFromUnixTimestamp(props.customer.paymentMethod.created) : ''}
                      </div>
                    </div>
                    {props.customer.paymentMethod.card.brand && (
                      <div className="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
                        {props.customer.paymentMethod.card.brand.toUpperCase()}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button onClick={e => deleteCard()} type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                      <i className="fa fa-trash mr-2 text-red-400" />
                      Delete
                    </button>
                  </span>
                </div>
              </div>
            )}
            {!props.customer.paymentMethod && (
              <div onClick={e => addCard()} className="col-span-1 flex flex-col justify-center items-center text-center text-gray-300 rounded-lg border-2 border-dashed border-gray-300 p-6 cursor-pointer">
                <svg className="h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"/>
                </svg>
                <p className="text-xl font-semibold mt-2">Add New Card</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Billing;