import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment';
import PubSub from "pubsub-js";
import { v4 as uuidv4 } from "uuid";
import api from "../../apis/public";
import config from "../../config/index";

const uuid = uuidv4();

const Page = (props) => {
  const [ page, setPage ] = useState({});
  const [ pageId, setPageId ] = useState([]);
  const [ photoUrl, setPhotoUrl ] = useState('');
  const [ links, setLinks ] = useState([]);
  const { url } = useParams();
  const [ error404, setError404 ] = useState(false);

  useEffect(() => {
    refreshPage();
  }, []);

  const refreshPage = async () => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      let page = await api.getPageByUrl(url);
      page = page.results[0];

      let links = await api.getLinks(page.id);
      setPage(page);
      setPageId(page.id);
      setPhotoUrl(`https://cdn.fuzz.link/${page.id}?${Date.now()}`);
      setLinks(links.results);

      // track session
      trackSession(page.id);
    } catch(err) {
      if (err === 'Error: Page does not exist.') {
        setError404(true);
      } else {
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      }
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const trackSession = (pageId) => {
    let session = {
      startDate: new Date().toISOString()
    };

    window.addEventListener('beforeunload', (e) => {
      session.endDate = new Date().toISOString();
      session.duration = moment(session.endDate).diff(session.startDate);
      session.windowEvent = 'beforeunload';

      // track session end
      const url = `${config.apiUrl}/events`;
      const body = {
        sessionId: uuid,
        pageId: pageId,
        eventType: 'session',
        referrer: document.referrer,
        linkId: null,
        session: session
      };
      navigator.sendBeacon(url, JSON.stringify(body));
    });

    window.addEventListener('pagehide', (e) => {
      session.endDate = new Date().toISOString();
      session.duration = moment(session.endDate).diff(session.startDate);
      session.windowEvent = 'pagehide';

      // track session end
      const url = `${config.apiUrl}/events`;
      const body = {
        sessionId: uuid,
        pageId: pageId,
        eventType: 'session',
        referrer: document.referrer,
        linkId: null,
        session: session
      };
      navigator.sendBeacon(url, JSON.stringify(body));
    });

    // track page view
    api.trackEvent(uuid, pageId, 'pageView');
  }

  const linkClicked = (link, event) => {
    event.persist();

    // track link click
    const url = `${config.apiUrl}/events`;
    const body = {
      sessionId: uuid,
      pageId: pageId,
      eventType: 'click',
      referrer: document.referrer,
      linkId: link.id,
      session: null
    };
    navigator.sendBeacon(url, JSON.stringify(body));
    
    // redirect to link
    window.location.href = link.url;
  }

  const isLive = (link) => {
    return !link.isTimed || (link.isTimed && moment().isBetween(link.startDate, link.endDate));
  }

  const linkStyle = (link) => {
    let defaultStyle = {
      backgroundColor: '#5e72e4',
      color: '#fff',
      borderColor: '#5e72e4',
      boxShadow: 'none'
    };

    if (link.options) {
      if (link.options.outline) {
        return {
          backgroundColor: '#fff',
          color: link.options.color ? link.options.color : link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4',
          borderColor: link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4',
          boxShadow: 'none'
        };
      } else {
        return {
          backgroundColor: link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4',
          color: link.options.color ? link.options.color : '#fff',
          borderColor: link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4',
          boxShadow: 'none'
        };
      }
    } else {
      return defaultStyle;
    }
  }

  const pageStyle = () => {
    let defaultStyle = {
      backgroundColor: '#fff'
    };

    if (page.options) {
      return {
        backgroundColor: page.options.backgroundColor ? page.options.backgroundColor : '#fff'
      };
    } else {
      return defaultStyle;
    }
  }

  const returnHome = () => {
    window.location.href = 'https://fuzz.link';
  }

  return (
    <>
      {page.id && (
        <div className="public-page-container" style={pageStyle()}>
          <div className="public-page">
            <div className="inner-container">
              <div className="card-top" style={pageStyle()}></div>
              <div className="justify-center m-0">
                <div className="flex justify-center photo-row">
                  <div className="photo-container">
                    <svg className="h-full w-full text-gray-300 bg-gray-100" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                    {page.url && (
                      <img
                        alt=""
                        className="rounded-circle object-cover absolute top-0 left-0"
                        src={photoUrl}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center border-0 p-4">
                <h3 className="text-gray-900 text-md leading-5 font-medium">@{page.url}</h3>
                <dd className="text-gray-500 text-sm leading-5 mt-2">{page.name}</dd>
              </div>
              <div className="pt-0 mt-3">
                {links && links.length > 0 && links.map((link, index) => {
                  return link.isActive && isLive(link) ? (
                    <div key={link.id} className={`flex mb-4 px-3`}>
                      <button
                        className="w-full items-center px-4 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                        type="button"
                        onClick={e => linkClicked(link, e)}
                        style={linkStyle(link)}
                      >
                        {link.name}
                      </button>
                    </div>
                  ) : null;
                })}
              </div>
            </div>
            <div onClick={e => linkClicked({ url: 'https://fuzz.link', id: null }, e)} className="preview-footer border-t border-gray-200 bg-white">
              {/* <img src={require("assets/img/brand/argon-react.png")} alt="Logo" /> */}
              {/* <img src="https://tailwindui.com/img/logos/workflow-logo-on-white.svg" alt="Workflow" /> */}
              <img src={require("assets/img/brand/logo-horizontal-on-white.png")} alt="FuzzLink" />
            </div>
          </div>
        </div>
      )}
      {error404 && (
        <div className="flex flex-col items-center justify-center w-full h-full text-gray-600 p-8">
          <svg className="h-20 w-20" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3542 15.3542C19.3176 15.7708 18.1856 16.0001 17 16.0001C12.0294 16.0001 8 11.9706 8 7.00006C8 5.81449 8.22924 4.68246 8.64581 3.64587C5.33648 4.9758 3 8.21507 3 12.0001C3 16.9706 7.02944 21.0001 12 21.0001C15.785 21.0001 19.0243 18.6636 20.3542 15.3542Z" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <h2 className="text-center text-3xl leading-9 font-extrabold sm:text-4xl sm:leading-10 mt-10">
            Oops!
          </h2>
          <h2 className="text-center text-xl leading-9 font-extrabold sm:text-2xl sm:leading-10 mt-3">
            Page does not exist.
          </h2>
          <button
            className="mt-20 items-center px-4 py-3 mb-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
            type="button"
            onClick={returnHome}
          >
            Go to FuzzLink
          </button>
        </div>
      )}
    </>
  );
}

export default Page;
