import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import PubSub from "pubsub-js";
import { useAuth0 } from "../../react-auth0-spa";

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#000',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      ':focus': {
        outline: 'none',
        boxShadow: '0 0 0 3px rgba(164, 202, 254, 0.45)',
        borderColor: '#a4cafe'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm = ({ customerId, callback }) => {
  const { stripeApi } = useAuth0();
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();

    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      const card = elements.getElement(CardElement);
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: card
      });
      if (result.error) {
        // Inform the user if there was an error.
        setError(result.error.message);
      } else {
        setError(null);
        createCustomerPaymentMethod(result.paymentMethod.id);
      }
    } catch(err) {
      setError(err);
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  };

  const createCustomerPaymentMethod = async (paymentMethodId) => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      await stripeApi.createCustomerPaymentMethod(customerId, paymentMethodId);
      PubSub.publish('modal', null);
      PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'Payment method added' });
      callback(paymentMethodId);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  return (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            Update Payment Method
          </h3>
          <div className="mt-4">
            <fieldset>
              <legend className="block text-sm font-medium leading-5 text-gray-700 text-left">Card Details</legend>
              <div className="mt-3 bg-white rounded-md shadow-sm">
                <div>
                  <input aria-label="Card number" className="form-input relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-md sm:leading-5" placeholder="Name on card (ex. John Doe)" />
                </div>
                <div className="-mt-px flex">
                  <CardElement
                    id="card-element"
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleChange}
                    className="form-input relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
            </fieldset>
            <div className="mt-3 card-errors block text-sm leading-5 text-red-600" role="alert">{error}</div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button onClick={e => handleSubmit(e)} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Submit
          </button>
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button onClick={e => PubSub.publish('modal', null)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Cancel
          </button>
        </span>
      </div>
    </div>
  );
}

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
}

export default CardForm;