import React, { useState } from "react";
import _ from 'lodash';
import PubSub from "pubsub-js";
import { useAuth0 } from "../../../react-auth0-spa";

const Credentials = (props) => {
  const { user, api, stripeApi } = useAuth0();
  const [ account, setAccount ] = useState({
    email: user.email
  });
  const [ emailError, setEmailError ] = useState(null);

  const updateUser = async (e) => {
    e.preventDefault();
    setEmailError(null);

    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      await api.updateUser(account);
      await stripeApi.updateCustomer(user.stripeCustomer.id, account);
      PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'Updated user account' });
      props.refreshPage();
    } catch(err) {
      if (err === 'The specified new email already exists') {
        setEmailError(err);
      }
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    }
  }

  const accountUpdating = (field, event) => {
    let value = '';

    switch(field) {
      case 'isActive':
        event.persist();
        value = !props.page[field];
        // updatePage(props.page.id, { [field]: value });
        break;
      default:
        event.persist();
        value = event.target.value;
        break;
    }

    let updatedAccount = Object.assign({}, account);
    _.set(updatedAccount, field, value);

    setAccount(updatedAccount);
  }

  // const pageUpdated = (field, event) => {
  //   event.persist();

  //   let value = event.target.value;
  //   let keys = field.split('.');
  //   let firstKey = keys[0];

  //   if (keys.length > 1) {
  //     let page = Object.assign({}, props.page);
  //     _.set(page, field, value);
  //     value = page[firstKey];
  //   }

  //   updatePage(props.page.id, { [firstKey]: value });
  // }

  const passwordChange = async () => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) throw new Error("You are missing the choiceUser retrieved from the choice api");
      const result = await api.passwordChange();
      if (result.ticket) {
        window.open(result.ticket, '_self'); 
        return null;
      } else {
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: 'Unable to change password.' });
      }
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  return (
    <>
      <form className="max-w-3xl mx-auto bg-white shadow sm:rounded-md px-4 py-5 sm:p-6">
        <div>
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-500">
                These are your login credentials.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                  Email address
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="email"
                    type="email"
                    value={account.email}
                    onChange={e =>
                      accountUpdating("email", e)
                    }
                    className={`form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${emailError ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red' : ''}`}
                    ariaInvalid={emailError}
                    ariaDescribedBy="email-error"
                  />
                  {emailError && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </div>
                  )}
                </div>
                {emailError && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">{emailError}</p>
                )}
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">Password</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <div className="relative flex-grow focus-within:z-10">
                    <input disabled id="password" type="password" value="********" className="form-input block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Current Password" />
                  </div>
                  <button onClick={e => passwordChange()} className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"/>
                    </svg>
                    <span className="ml-2">Change</span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <span className="inline-flex rounded-md shadow-sm">
              <button type="button" className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                Cancel
              </button>
            </span>
            <span className="ml-3 inline-flex rounded-md shadow-sm">
              <button onClick={e => updateUser(e)} className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                Save
              </button>
            </span>
          </div>
        </div>
      </form>
    </>
  );
}

export default Credentials;