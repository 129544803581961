import React from "react";
import moment from 'moment';
import PubSub from "pubsub-js";

import { hasPremiumSubscription } from "../../../utils/stripe";
import UpgradePrompt from "components/Stripe/UpgradePrompt.jsx";

const Preview = (props) => {
  const linkClicked = (link, event) => {
    event.persist();

    if (!link.url.match(/^https?:\/\//i)) {
      link.url = 'http://' + link.url;
    }

    window.open(link.url, '_blank');
  }

  const isLive = (link) => {
    return !link.isTimed || (link.isTimed && moment().isBetween(link.startDate, link.endDate));
  }

  const linkStyle = (link) => {
    let defaultStyle = {
      backgroundColor: '#5e72e4',
      color: '#fff',
      borderColor: '#5e72e4',
      boxShadow: 'none'
    };

    if (link.options) {
      if (link.options.outline) {
        return {
          backgroundColor: '#fff',
          color: link.options.color ? link.options.color : link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4',
          borderColor: link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4',
          boxShadow: 'none'
        };
      } else {
        return {
          backgroundColor: link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4',
          color: link.options.color ? link.options.color : '#fff',
          borderColor: link.options.backgroundColor ? link.options.backgroundColor : '#5e72e4',
          boxShadow: 'none'
        };
      }
    } else {
      return defaultStyle;
    }
  }

  const pageStyle = () => {
    let defaultStyle = {
      backgroundColor: '#172b4d'
    };

    if (props.page.options) {
      return {
        backgroundColor: props.page.options.backgroundColor ? props.page.options.backgroundColor : '#172b4d'
      };
    } else {
      return defaultStyle;
    }
  }

  const openFeatureHelper = async () => {
    PubSub.publish('modal', (
      <UpgradePrompt />
    ));
  }

  return (
    <>
      {props.page.id && (
        <div className="public-page-container" style={pageStyle()}>
          <div className="public-page">
            <div className="inner-container">
              <div className="card-top" style={pageStyle()}></div>
              <div className="justify-center m-0">
                <div className="flex justify-center photo-row">
                  <div className="photo-container">
                    <svg className="h-full w-full text-gray-300 bg-gray-100" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                    {props.photoUrl && (
                      <img
                        alt=""
                        className="rounded-circle object-cover absolute top-0 left-0"
                        src={props.photoUrl}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center border-0 p-4">
                <h3 className="text-gray-900 text-md leading-5 font-medium">@{props.page.url || 'mypage'}</h3>
                <dd className="text-gray-500 text-sm leading-5 mt-2">{props.page.name || 'My Page Name'}</dd>
              </div>
              <div className="pt-0 mt-3">
                {props.links && props.links.length > 0 && props.links.map((link, index) => {
                  return link.isActive && isLive(link) ? (
                    <div key={link.id} className={`flex mb-4 px-3 relative`}>
                      <button
                        className={`${!hasPremiumSubscription(props.user.stripeCustomer) && index > 2 ? 'opacity-25' : ''} w-full items-center px-4 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
                        type="button"
                        onClick={e => linkClicked(link, e)}
                        style={linkStyle(link)}
                      >
                        {link.name}
                      </button>
                      {(!hasPremiumSubscription(props.user.stripeCustomer) && index > 2) && (
                        <button
                          className={`bg-red-400 hover:bg-red-500 text-white rounded-full h-8 w-8 flex items-center justify-center p-0 text-sm absolute top-0 right-0 -mt-1 mr-1 shadow`}
                          type="button"
                          onClick={openFeatureHelper}
                        >
                          <i className="fa fa-exclamation-triangle"></i>
                        </button>
                      )}
                    </div>
                  ) : null;
                })}
              </div>
            </div>
            <div onClick={e => linkClicked({ url: 'https://fuzz.link', id: null }, e)} className="preview-footer border-t border-gray-200 bg-white">
              <img src={require("assets/img/brand/logo-horizontal-on-white.png")} alt="FuzzLink" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Preview;