const hasPremiumSubscription = (customer) => {
  const subscription = customer.subscriptions.data[0];
  if (subscription) {
    const priceId = subscription.plan.id;
    return priceId === process.env.REACT_APP_STRIPE_PRICE_ID_PRO_MONTH || priceId === process.env.REACT_APP_STRIPE_PRICE_ID_PRO_YEAR;
  }
  return false;
};

module.exports = {
  hasPremiumSubscription
};