import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import queryString from "query-string";
import PubSub from "pubsub-js";

// account components
import Credentials from "./Credentials.jsx";
import Billing from "./Billing.jsx";
import Notifications from "./Notifications.jsx";
import Subscription from "./Subscription.jsx";

import { useAuth0 } from "../../../react-auth0-spa";

const Account = (props) => {
  const { user, api, stripeApi, refreshUser } = useAuth0();
  const [ activeTab, setActiveTab ] = useState(queryString.parse(props.location.search).tab || 'credentials');
  const [ customer, setCustomer ] = useState({});
  const [ subscription, setSubscription ] = useState({});
  const [ pageId, setPageId ] = useState([]);
  const [ photoUrl, setPhotoUrl ] = useState('');
  const [ links, setLinks ] = useState([]);
  const { url } = useParams();
  const [ showSubscriptionPage, setShowSubscriptionPage ] = useState(false);
  const [ invoicePreview, setInvoicePreview ] = useState(null);

  useEffect(() => {
    refreshPage();

    if (queryString.parse(props.location.search).pricing) {
      setShowSubscriptionPage(true);
    }
  }, []);

  const refreshPage = async () => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      let customer = await stripeApi.getCustomer(user.appUser.user_metadata.stripeCustomerId);
      let paymentMethods = await stripeApi.getCustomerPaymentMethods(user.appUser.user_metadata.stripeCustomerId);
      let subscription = customer.subscriptions.data[0];
      setSubscription(subscription);
      let upcomingInvoice = await stripeApi.getUpcomingInvoice(customer.id, subscription.id);
      setInvoicePreview(upcomingInvoice);
      
      customer.subscription = subscription;
      customer.priceId = subscription.plan.id;
      customer.paymentMethods = paymentMethods.data;
      customer.paymentMethod = paymentMethods.data[0];
      customer.upcomingInvoice = upcomingInvoice;
      setCustomer(customer);

      refreshUser();
    } catch(err) {
      console.log(err);
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const tabChanged = (tab) => {
    setShowSubscriptionPage(false);
    setActiveTab(tab);
  }

  return (
    <>
      <div className="md:flex md:items-center md:justify-between bg-white py-4 px-6 pb-0">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate">
            Account
          </h2>
        </div>
      </div>
      <div className="editor-nav border-b border-gray-200 py-0 px-3">
        <div className={classnames({ active: activeTab === 'credentials', 'editor-tab': true })} onClick={e => { tabChanged('credentials')  }}>
          <p>Credentials</p>
          <span></span>
        </div>
        <div className={classnames({ active: activeTab === 'billing', 'editor-tab': true })} onClick={e => { tabChanged('billing') }}>
          <p>Billing</p>
          <span></span>
        </div>
        {/* <div className={classnames({ active: activeTab === 'notifications', 'editor-tab': true })} onClick={e => { setActiveTab('notifications') }}>
          <p>Notifications</p>
          <span></span>
        </div> */}
      </div>

      <div className="flex-grow w-full h-full lg:flex m-0 p-0 overflow-y-auto overflow-x-hidden">
        <div className={`flex flex-col p-0 w-full overflow-y-auto overflow-x-hidden`}>
          <div className="overflow-y-auto overflow-x-hidden h-full">
            {!showSubscriptionPage && (
              <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
                {activeTab === 'credentials' && (
                  <Credentials user={user} refreshPage={refreshPage} />
                )}
                {activeTab === 'billing' && (
                  <Billing customer={customer} refreshPage={refreshPage} setShowSubscriptionPage={setShowSubscriptionPage} />
                )}
                {/* {activeTab === 'notifications' && (
                  <Notifications user={user} refreshPage={refreshPage} />
                )} */}
              </div>
            )}
            {showSubscriptionPage && (
              <Subscription refreshPage={refreshPage} setShowSubscriptionPage={setShowSubscriptionPage} accountInformation={customer} />
            )}
          </div>
        </div>
      </div>
      
    </>
  );
}

export default Account;
