import config from "../config/index";

/**
 * IMPORTANT: this class is instantiated from within the auth0 provider function and becomes accessable after importing useAuth0 via: import { useAuth0 } from "../react-auth0-spa";
 * - It is not recommended to instantiated it manually outside of auth0 because it depends on auth0 specific perameters that allow the use of the access token for the logged in user.
 */
export class API {
  accessToken = Promise
  user = {}
  auth0Client = {}
  test = "test"
  url = ""
  constructor (auth0Client, user) {
    this.auth0Client = auth0Client;
    this.user = user;
    this.accessToken = auth0Client.getTokenSilently();
  }

  handleResponse = async (response) => {
    const string = await response.text();
    let json = {};
    if (typeof string === 'string' && string.trim() !== '') {
      try {
        json = JSON.parse(string);
      } catch(err) {
        console.log('Invalid error received from server: ', err);
      }
    }

    if (response.status === 200) {
      // success
      return json;
    } else {
      // error
      throw this.errorHandler(json);
    }
  }

  errorHandler = (err) => {
    let message = 'There was a problem with your request.';

    if (typeof err === 'string') {
      message = err;
    } else if (err.errors && err.errors.message) {
      message = err.errors.message;
    } else if (typeof message !== 'string') {
      message = JSON.stringify(message);
    }

    return message;
  }

  getUser = async () => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/auth0/user`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  updateUser = async (body) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/auth0/user`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return await this.handleResponse(response);
  }

  createPage = async () => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  getPages = async () => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  getPage = async (pageId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  getPageByUrl = async (pageUrl) => {
    // const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/url/${pageUrl}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        // 'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  updatePage = async (pageId, body) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return await this.handleResponse(response);
  }

  getLinks = async (pageId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}/links`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  createLink = async (pageId, body) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}/links`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return await this.handleResponse(response);
  }

  deleteLink = async (pageId, linkId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}/links/${linkId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  updateLink = async (pageId, linkId, body) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}/links/${linkId}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return await this.handleResponse(response);
  }

  reorderLinks = async (pageId, linkId, body) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}/links/${linkId}/reorder`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return await this.handleResponse(response);
  }

  generatePutUrl = async (pageId, file) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}/generate-put-url?key=${pageId}&contentType=${file.type}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  deletePhoto = async (pageId) => {
    const accessToken = await this.accessToken;
    const url = `${config.apiUrl}/pages/${pageId}/delete-photo?key=${pageId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  getRole = async () => {
    const accessToken = await this.accessToken
    const url = `${config.apiUrl}/auth0/users/role`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  getAnalytics = async (pageId, start, end, range) => {
    const accessToken = await this.accessToken
    const url = `${config.apiUrl}/pages/${pageId}/analytics?start=${start}&end=${end}&range=${range}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }

  passwordChange = async () => {
    const accessToken = await this.accessToken
    const url = `${config.apiUrl}/auth0/password-change`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
    });
    return await this.handleResponse(response);
  }
}
