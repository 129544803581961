import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PubSub from "pubsub-js";
import routes from "routes.js";
import { useAuth0 } from "react-auth0-spa";
import history from "utils/history";
import ReactivateConfirm from "components/Stripe/ReactivateConfirm.jsx";


const Admin = (props) => {
  const { user, logout, api, stripeApi } = useAuth0();
  const [ accountOptionsVisible, setAccountOptionsVisible ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState(window.location.pathname || null);
  const [ mobileMenuVisible, setMobileMenuVisible ] = useState(false);

  useEffect(() => {
    let subscription = user && user.stripeCustomer && user.stripeCustomer.subscriptions ? user.stripeCustomer.subscriptions.data[0] : {};
    if (!subscription || (subscription && subscription.status === 'canceled')) {
      reactivateSubscriptionPrompt();
    }
  }, [])

  const logoutWithRedirect = () => {
    logout({  
      returnTo: window.location.origin
    });
  }

  // componentDidUpdate(e) {
  //   if (e.history.pathname !== e.location.pathname) {
  //     document.documentElement.scrollTop = 0;
  //     document.scrollingElement.scrollTop = 0;
  //     this.refs.mainContent.scrollTop = 0;
  //   }
  // }

  const reactivateSubscriptionPrompt = async () => {
    PubSub.publish('modal', (
      <ReactivateConfirm
        callback={async () => {
          PubSub.publish('loading', true);
          let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

          try {
            let subscription = user && user.stripeCustomer && user.stripeCustomer.subscriptions ? user.stripeCustomer.subscriptions.data[0] : {};
            await stripeApi.reactivateSubscription(user.stripeCustomer.id, subscription && subscription.id ? subscription.id : null);
            PubSub.publish('modal', null);
            PubSub.publish('alert', { type: 'success', title: 'Success!', body: 'Account was reactivated' });
            history.push('/admin/home');
          } catch(err) {
            PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
          } finally {
            PubSub.publish('loading', false);
            clearTimeout(timeout);
          }
        }}
      />
    ));
  }

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const goToLink = (path) => {
    if (path === '/admin/home' || path === '/admin/account') {
      setCurrentPage(path);
    }
    history.push(path);
    setAccountOptionsVisible(false);
    setMobileMenuVisible(false);
  };
  

  return (
    <>
      <div onClick={e => { setAccountOptionsVisible(false); }} className="h-screen flex overflow-hidden bg-gray-100">
        {/* Off-canvas menu for mobile */}
        {mobileMenuVisible && (

          <div className="md:hidden">
            <div className="fixed inset-0 flex z-40">
              {/*
                Off-canvas menu overlay, show/hide based on off-canvas menu state.

                Entering: "transition-opacity ease-linear duration-300"
                  From: "opacity-0"
                  To: "opacity-100"
                Leaving: "transition-opacity ease-linear duration-300"
                  From: "opacity-100"
                  To: "opacity-0"
              */}
              <div className="fixed inset-0">
                <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
              </div>
              {/*
                Off-canvas menu, show/hide based on off-canvas menu state.

                Entering: "transition ease-in-out duration-300 transform"
                  From: "-translate-x-full"
                  To: "translate-x-0"
                Leaving: "transition ease-in-out duration-300 transform"
                  From: "translate-x-0"
                  To: "-translate-x-full"
              */}
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <div className="absolute top-0 right-0 -mr-14 p-1">
                  <button onClick={e => setMobileMenuVisible(false)} className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar">
                    <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img className="h-8 w-auto" src={require("assets/img/brand/logo-horizontal-on-white.png")} alt="FuzzLink" />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2">
                    <a onClick={e => goToLink('/admin/home')} href="#" className={`group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md ${currentPage === '/admin/home' ? 'bg-gray-100' : 'bg-white'} hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150`}>
                      <svg className="mr-4 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                      </svg>
                      My Pages
                    </a>
                    <a onClick={e => goToLink('/admin/account')} href="#" className={`group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md ${currentPage === '/admin/account' ? 'bg-gray-100' : 'bg-white'} hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150`}>
                      <svg className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                      Account
                    </a>
                    <a onClick={e => goToLink('/admin/contact')} href="#" className={`group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md ${currentPage === '/admin/account' ? 'bg-gray-100' : 'bg-white'} hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150`}>
                      <svg className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                        <path d="M8 12H8.01M12 12H12.01M16 12H16.01M21 12C21 16.4183 16.9706 20 12 20C10.4607 20 9.01172 19.6565 7.74467 19.0511L3 20L4.39499 16.28C3.51156 15.0423 3 13.5743 3 12C3 7.58172 7.02944 4 12 4C16.9706 4 21 7.58172 21 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Contact Us
                    </a>
                  </nav>
                </div>
              </div>
              <div className="flex-shrink-0 w-14">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </div>
        )}

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img className="h-8 w-auto" src={require("assets/img/brand/logo-horizontal-on-white.png")} alt="Workflow" />
              </div>
              <div className="mt-5 flex-grow flex flex-col">
                <nav className="flex-1 px-2 bg-white">
                  <a onClick={e => goToLink('/admin/home')} href="#" className={`group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md ${currentPage === '/admin/home' ? 'bg-gray-100' : 'bg-white'} hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150`}>
                    <svg className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    My Pages
                  </a>
                  <a onClick={e => goToLink('/admin/account')} href="#" className={`group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md ${currentPage === '/admin/account' ? 'bg-gray-100' : 'bg-white'} hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150`}>
                    <svg className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                    Account
                  </a>
                  <a onClick={e => goToLink('/admin/contact')} href="#" className={`group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md ${currentPage === '/admin/account' ? 'bg-gray-100' : 'bg-white'} hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150`}>
                    <svg className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                      <path d="M8 12H8.01M12 12H12.01M16 12H16.01M21 12C21 16.4183 16.9706 20 12 20C10.4607 20 9.01172 19.6565 7.74467 19.0511L3 20L4.39499 16.28C3.51156 15.0423 3 13.5743 3 12C3 7.58172 7.02944 4 12 4C16.9706 4 21 7.58172 21 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Contact Us
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button onClick={e => setMobileMenuVisible(true)} className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden" aria-label="Open sidebar">
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
              </svg>
            </button>
            <div className="flex-1 px-4 flex justify-end">
              {/* <div className="flex-1 flex">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search_field" className="sr-only">Search</label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                      </svg>
                    </div>
                    <input id="search_field" className="block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm" placeholder="Search" type="search" />
                  </div>
                </form>
              </div> */}
              <div className="ml-4 flex items-center md:ml-6">
                {/* <button className="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" aria-label="Notifications">
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                </button> */}

                {/* Profile dropdown */}
                <div className="ml-3 relative">
                  <div>
                    <button onClick={e => { e.stopPropagation(); e.nativeEvent.stopImmediatePropagation(); setAccountOptionsVisible(!accountOptionsVisible) }} className="max-w-xs flex items-center text-sm focus:outline-none focus:shadow-none" id="user-menu" aria-label="User menu" aria-haspopup="true">
                      {/* <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> */}
                      <span className="mb-0 text-sm font-weight-bold d-none d-lg-block">
                        {user ? user.email : 'My Account'}
                      </span>
                      <span className="mb-0 text-sm font-weight-bold">
                        <i className="fa fa-chevron-down" style={{ fontSize: 12, marginLeft: 6 }}></i>
                      </span>
                    </button>
                  </div>
                  {/*
                    Profile dropdown panel, show/hide based on dropdown state.

                    Entering: "transition ease-out duration-100"
                      From: "transform opacity-0 scale-95"
                      To: "transform opacity-100 scale-100"
                    Leaving: "transition ease-in duration-75"
                      From: "transform opacity-100 scale-100"
                      To: "transform opacity-0 scale-95"
                  */}
                  {accountOptionsVisible && (
                    <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                      <div className="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <a onClick={e => goToLink('/admin/account')} href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150" role="menuitem">Account</a>
                        <a onClick={e => logoutWithRedirect()} href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150" role="menuitem">Sign out</a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <main className="flex flex-col flex-1 relative z-0 focus:outline-none overflow-hidden" tabIndex="0">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/home" />
            </Switch>
          </main>
        </div>
      </div>
    </>
  );
}


export default Admin;
