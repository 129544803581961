import React, { useEffect, useState, useRef, createRef } from "react";
import classnames from "classnames";
import queryString from "query-string";
import PubSub from "pubsub-js";

// editor components
import Settings from "./Settings.jsx";
import Links from "./Links.jsx";
import Preview from "./Preview.jsx";
import QRCodePage from "./QRCodePage.jsx";
import Analytics from "./Analytics.jsx";

import { useAuth0 } from "../../../react-auth0-spa";
import history from "../../../utils/history";

const Editor = (props) => {
  const { user, api } = useAuth0();
  const [ activeTab, setActiveTab ] = useState(queryString.parse(props.location.search).tab || 'settings');
  const [ page, setPage ] = useState([]);
  const [ savedPage, setSavedPage ] = useState({});
  const [ photoUrl, setPhotoUrl ] = useState(null);
  const [ links, setLinks ] = useState([]);
  const [ prevLinks, setPrevLinks ] = useState([]);
  const { pageId } = queryString.parse(props.location.search);
  const scrollableView = useRef();
  const pageLink = createRef();
  const [ pageLinkCopied, setPageLinkCopied ] = useState(false);

  useEffect(() => {
    refreshPage(true);
  }, []);

  const refreshPage = async (loading) => {
    if (loading) {
      PubSub.publish('loading', true);
    }
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);
    
    try {
      // if ( Object.keys(user.choiceUser).length === 0 ) thdiv new Error("You are missing the choiceUser retrieved from the choice api");
      let page = await api.getPage(pageId);
      page = page.results[0];
      let links = await api.getLinks(pageId);
      links = links.results;
      setPage(page);
      setSavedPage(Object.assign({}, JSON.parse(JSON.stringify(page))));

      const photoUrl = `https://cdn.fuzz.link/${pageId}?${Date.now()}`;
      fetch(photoUrl, {
        method: 'GET'
      }).then(photoExists => {
        setPhotoUrl(photoExists.status === 200 ? photoUrl : null);
      }).finally(() => {
        if (page.positions) {
          page.positions = page.positions.filter(p => {
            let exists = links.find(l => l.id == p);
            return exists ? true : false;
          });

          links = page.positions.map(p => {
            return links.find(l => l.id == p);
          });

          setLinks(links);
          setPrevLinks(Object.assign([], JSON.parse(JSON.stringify(links))));
        }
      });
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      history.push('admin/home');
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const copyPageLink = () => {
    let text = pageLink.current.innerText;
    let elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    setPageLinkCopied(true);

    setTimeout(() => {
      setPageLinkCopied(false);
    }, 3000);
  }

  return (
    <>
      <div className="md:flex md:items-center md:justify-between bg-white py-4 px-6 pb-0">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate">
            Editor
          </h2>
        </div>
      </div>
      <div className="editor-nav border-b border-gray-200 py-0 px-3">
        <div className={classnames({ active: activeTab === 'settings', 'editor-tab': true })} onClick={e => { setActiveTab('settings') }}>
          <p>Page Settings</p>
          <span></span>
        </div>
        <div className={classnames({ active: activeTab === 'links', 'editor-tab': true })} onClick={e => { setActiveTab('links') }}>
          <p>Links</p>
          <span></span>
        </div>
        <div className={classnames({ active: activeTab === 'qr', 'editor-tab': true })} onClick={e => { setActiveTab('qr') }}>
          <p>QR Code</p>
          <span></span>
        </div>
        <div className={classnames({ active: activeTab === 'analytics', 'editor-tab': true })} onClick={e => { setActiveTab('analytics') }}>
          <p>Analytics</p>
          <span></span>
        </div>
      </div>

      <div className="bg-indigo-600">
        <div className="mx-auto py-2 px-4 sm:px-4 lg:px-6">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-indigo-800">
                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
              </span>
              <p ref={pageLink} className="ml-3 font-medium text-white truncate">
                fuzz.link/{page.url}
              </p>
            </div>
            <div className="flex-shrink-0 sm:mt-0">
              <div className="rounded-md shadow-sm">
                <a onClick={e => copyPageLink()} href="#" className="flex items-center justify-center px-2 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                  {pageLinkCopied ? 'Copied!' : 'Copy Link'}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow w-full h-full lg:flex m-0 p-0 overflow-y-auto overflow-x-hidden">
        <div className="overflow-y-auto overflow-x-hidden p-6 min-w-0 w-full" ref={scrollableView} style={{ scrollBehavior: 'smooth' }}>
          {(page && page.id && activeTab === 'settings') && (
            <Settings page={page} savedPage={savedPage} photoUrl={photoUrl} refreshPage={refreshPage} setPage={setPage} />
          )}
          {(page && page.id && activeTab === 'links') && (
            <Links page={page} savedPage={savedPage} links={links} prevLinks={prevLinks} setLinks={setLinks} refreshPage={refreshPage} scrollableView={scrollableView} />
          )}
          {(page && page.id && activeTab === 'qr') && (
            <QRCodePage page={page} user={user} />
          )}
          {(page && page.id && activeTab === 'analytics') && (
            <Analytics page={page} links={links} user={user} />
          )}
        </div>
        {activeTab !== 'analytics' && (
          <div className="flex justify-center items-center relative p-0 h-full w-full lg:max-w-md lg:border-l lg:border-gray-200">
            <Preview page={page} links={links} photoUrl={photoUrl} refreshPage={refreshPage} user={user} />
          </div>
        )}
      </div>
    </>
  );
}

export default Editor;
